import { AxiosResponse } from 'axios'
import { get } from '../api'

import { Empresa } from '../models'

const controller = 'empresa'


export async function GetAll(): Promise<Empresa[]> {
   const http = await get();
 const { data } = await http.get<Empresa[], AxiosResponse>(`${controller}/all`);
    return data;
}


export async function Get(id: number): Promise<Empresa> {
   const http = await get();
 const { data } = await http.get<Empresa, AxiosResponse>(`${controller}/${id}`);
    return data;
}


export async function Post(andar: Empresa): Promise<Empresa> {
   const http = await get();
 const { data } = await http.post<Empresa, AxiosResponse>(`${controller}`, andar);
    return data;
}


export async function Put(andar: Empresa): Promise<Empresa> {
   const http = await get();
 const { data } = await http.put<Empresa, AxiosResponse>(`${controller}`, andar);
    return data;
}

export async function Delete(id: number): Promise<Empresa> {
   const http = await get();
 const { data } = await http.delete<Empresa, AxiosResponse>(`${controller}/${id}`);
    return data;
}


export async function paginate(page: number = 1, size: number = 10, termoPesquisa?: string): Promise<Empresa[]> {
   const http = await get();
 const { data } = await http.get<Empresa[], AxiosResponse>(`${controller}/paginate?page=${page}&size=${size}${termoPesquisa ? `&termoPesquisa=${termoPesquisa}` : ""}`);
    return data;
}
