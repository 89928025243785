import { AxiosResponse } from 'axios'
import { get } from '../api'

import { Controladora } from '../models'

const controller = 'controladora'

export async function GetModelos(): Promise<any[]> {
   const http = await get();
 const { data } = await http.get<any[], AxiosResponse>(`${controller}/modelo`);
    return data;
}

export async function GetTipos(): Promise<any[]> {
   const http = await get();
 const { data } = await http.get<any[], AxiosResponse>(`${controller}/tipo`);
    return data;
}


export async function GetControladoras(): Promise<any[]> {
   const http = await get();
 const { data } = await http.get<any[], AxiosResponse>(`${controller}/controladoras`);
    return data;
}


export async function TrocaIp(trocaIp: any): Promise<any[]> {
   const http = await get();
 const { data } = await http.post<any, AxiosResponse>(`${controller}/novo-ip`, trocaIp);
    return data;
}
export async function GetAll(): Promise<Controladora[]> {
   const http = await get();
 const { data } = await http.get<Controladora[], AxiosResponse>(`${controller}/all`);
    return data;
}

export async function GetDispositivos(): Promise<any[]> {
   const http = await get();
 const { data } = await http.get<any[], AxiosResponse>(`${controller}/dispositivos`);
    return data;
}

export async function Get(id: number): Promise<Controladora> {
   const http = await get();
 const { data } = await http.get<Controladora, AxiosResponse>(`${controller}/${id}`);
    return data;
}


export async function Post(andar: Controladora): Promise<Controladora> {
   const http = await get();
 const { data } = await http.post<Controladora, AxiosResponse>(`${controller}`, andar);
    return data;
}


export async function Put(andar: Controladora): Promise<Controladora> {
   const http = await get();
 const { data } = await http.put<Controladora, AxiosResponse>(`${controller}`, andar);
    return data;
}

export async function Delete(id: number): Promise<Controladora> {
   const http = await get();
 const { data } = await http.delete<Controladora, AxiosResponse>(`${controller}/${id}`);
    return data;
}


export async function paginate(page: number = 1, size: number = 10, termoPesquisa?: string): Promise<Controladora[]> {
   const http = await get();
 const { data } = await http.get<Controladora[], AxiosResponse>(`${controller}/paginate?page=${page}&size=${size}${termoPesquisa ? `&termoPesquisa=${termoPesquisa}` : ""}`);
    return data;
}
