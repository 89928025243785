import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_smart_home = _resolveComponent("smart-home")!
  const _component_clientHome = _resolveComponent("clientHome")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.adm)
      ? (_openBlock(), _createBlock(_component_smart_home, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.client)
      ? (_openBlock(), _createBlock(_component_clientHome, { key: 1 }))
      : _createCommentVNode("", true)
  ], 64))
}