import { AxiosResponse } from 'axios'
import { get } from '../api'

import { Convite } from '../models'

const controller = 'convite'



export async function GetAll(): Promise<Convite[]> {
   const http = await get();
 const { data } = await http.get<Convite[], AxiosResponse>(`${controller}/all`);
    return data;
}


export async function GetByToken(token: string): Promise<Convite> {
   const http = await get();
 const { data } = await http.get<Convite[], AxiosResponse>(`${controller}/get/${token}`);
    return data;
}

export async function Post(item: Convite): Promise<Convite> {
   const http = await get();
 const { data } = await http.post<Convite, AxiosResponse>(`${controller}`, item);
    return data;
}


export async function Put(item: Convite): Promise<Convite> {
   const http = await get();
 const { data } = await http.put<Convite, AxiosResponse>(`${controller}`, item);
    return data;
}

export async function Delete(id: number): Promise<Convite> {
   const http = await get();
 const { data } = await http.put<Convite, AxiosResponse>(`${controller}/${id}`);
    return data;
}


// export async function paginate(page: number = 1, size: number = 10, termoPesquisa?: string): Promise<Veiculo[]> {
//    const http = await get();
//  const { data } = await http.get<Veiculo[], AxiosResponse>(`${controller}/paginate?page=${page}&size=${size}${termoPesquisa ? `&termoPesquisa=${termoPesquisa}` : ""}`);
//     return data;
// }