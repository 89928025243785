export enum eParentesco {
    Pai = 0,
    Mae = 1,
    AvoMae = 2,
    AvoPai = 3,
    Tio = 4,
    Tia = 5,
    Primo = 6,
    Prima = 7,
    Esposa = 8,
    Marido = 9,
    Sogra = 10,
    Sogro = 11,
    Filho = 12,
    Filha = 13,
    Neto = 14,
    Neta = 15,
    Irmao = 16,
    Irma = 17,
    Sobrinho = 18,
    Sobrinha = 19,

    BisavoPai = 20,
    BisavoMae = 21,

    Bisneto = 22,
    Bisneta = 23,

    Nora = 24,
    Genro = 25,
}