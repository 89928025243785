import { AxiosResponse } from 'axios'
import { get } from '../api'

import { Licenca } from '../models'

const controller = 'licenca'

export async function licenca(payload: Licenca, file: File): Promise<AxiosResponse> {

  const form = new FormData();
  form.append("file", file);
  Object.keys(payload).map(f => {
    form.append(f, payload[f]);
  })
  const http = await get();

  return await http.post<boolean, AxiosResponse>(`${controller}`, form, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
}
