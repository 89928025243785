<template>
  <div class="form-check form-switch ps-0">
    <input v-model="check" :id="id" class="form-check-input ms-0" :class="inputClass" type="checkbox" :name="name"
      :checked="checked" />
    <label class="form-check-label" :class="labelClass" :for="id">
      <slot />
    </label>
  </div>
</template>

<script>
export default {
  name: "ArgonSwitch",
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    labelClass: {
      type: String,
      default: "",
    },
    inputClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      check: this.modelValue
    }
  },
  watch: {
    check() {
      this.$emit('update:modelValue', this.check)
    },
    modelValue() {
      this.check = this.modelValue
    }
  }
};
</script>
