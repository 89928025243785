<template>
  <nav
    id="navbarBlur"
    :class="`${
      !isNavFixed
        ? 'navbar navbar-main navbar-expand-lg px-0  shadow-none '
        : `navbar navbar-main navbar-expand-lg px-0  shadow-none position-sticky mb-3 ${
            darkMode ? 'bg-default' : 'bg-white'
          }`
    } ${isRTL ? 'top-0 position-sticky z-index-sticky' : ''}`"
    v-bind="$attrs"
    data-scroll="true"
  >
    <div class="container-fluid">
      <div class="d-flex">
        <router-link
          v-if="isUser"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Meus dados"
          :to="{ name: 'Minha conta' }"
          class="text-black"
        >
          <i class="fa fa fa-user-circle-o"></i>
          <span class="d-inline me-3"> Meus Dados</span>
        </router-link>
        <a
          href="manual2.pdf"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Manual"
          download
          class="text-black"
          ><i class="fa-solid fa-book"></i><span class="d-inline"> Manual</span></a
        >
      </div>
      <VDropdown :distance="6">
        <button class="bg-transparent border-0">
          <span class="text-red text-truncate">
            <i class="fas fa-user"></i>
            {{ user }}
          </span>
        </button>
        <template #popper>
          <ul class="p-3 d-flex flex-column gap-3 text-gray-400">
            <li class="d-flex align-items-center">
              <a
                href="manual2.pdf"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Manual"
                download
                class="text-black"
                ><i class="fa-solid fa-book me-3"></i><span class="d-inline"> Manual</span></a
              >
            </li>
            <li class="d-flex align-items-center my">
              <router-link
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Meus dados"
                :to="{ name: 'Minha conta' }"
                class="text-black"
              >
                <i class="fa fa fa-user-circle-o me-3"></i>
                <span class="d-inline"> Meus Dados</span>
              </router-link>
            </li>
            <li class="d-flex align-items-center" style="cursor: pointer">
              <i class="fas fa-key me-3"></i>
              <span class="d-inline" data-bs-toggle="modal" data-bs-target="#exampleModal">Alterar senha</span>
            </li>
            <li class="d-flex align-items-center" style="cursor: pointer" @click.stop="logout">
              <span class="d-inline"><i class="fas fa-sign-out-alt me-3"></i>Sair</span>
            </li>
          </ul>
        </template>
      </VDropdown>

      <div class="menu-togle-btn d-block d-xl-none">
        <div class="sidenav-toggler sidenav-toggler-inner" :class="isRTL ? 'me-3' : ''">
          <a href="#" class="nav-link bg-transparent mt-2 fs-1" @click.prevent="navbarMinimize">
            <div class="sidenav-toggler-inner">
              <i class="sidenav-toggler-line" :class="isNavFixed && !darkMode ? '  bg-dark' : 'bg-white'"></i>
              <i class="sidenav-toggler-line" :class="isNavFixed && !darkMode ? '  bg-dark' : 'bg-white'"></i>
              <i class="sidenav-toggler-line" :class="isNavFixed && !darkMode ? '  bg-dark' : 'bg-white'"></i>
            </div>
          </a>
        </div>
      </div>
    </div>
    <!-- <div class="menu-togle-btn position-fixed d-block d-xl-none">
      <div class="sidenav-toggler sidenav-toggler-inner" :class="isRTL ? 'me-3' : ''">
        <a href="#" class="nav-link bg-transparent mt-2 fs-1" @click.prevent="navbarMinimize">
          <div class="sidenav-toggler-inner">
            <i class="sidenav-toggler-line" :class="isNavFixed && !darkMode ? '  bg-dark' : 'bg-white'"></i>
            <i class="sidenav-toggler-line" :class="isNavFixed && !darkMode ? '  bg-dark' : 'bg-white'"></i>
            <i class="sidenav-toggler-line" :class="isNavFixed && !darkMode ? '  bg-dark' : 'bg-white'"></i>
          </div>
        </a>
      </div>
    </div> -->
  </nav>
  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Alterar senha</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <h5 class="text-center">Configure a nova senha</h5>
          <div class="form-group col-md-12">
            <label>Senha</label>
            <div class="input-group w-auto">
              <input
                class="form-control"
                style="height: 40px !important"
                v-model="newPassword"
                :type="typePass ? 'text' : 'password'"
              />
              <span id="button-addon1" class="btn" @click.prevent="typePass = !typePass">
                <i class="fa fa-eye" v-if="typePass"></i>
                <i class="fa fa-eye-slash" v-else></i>
              </span>
            </div>
          </div>
          <div class="form-group col-md-12">
            <label>Confirmar Senha</label>
            <div class="input-group w-auto">
              <input
                class="form-control"
                style="height: 40px !important"
                :type="typePassConfim ? 'text' : 'password'"
                v-model="newConfirmPassword"
              />
              <span id="button-addon1" class="btn" @click.prevent="typePassConfim = !typePassConfim">
                <i class="fa fa-eye" v-if="typePassConfim"></i>
                <i class="fa fa-eye-slash" v-else></i>
              </span>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-white text-black" id="fechaModal" data-bs-dismiss="modal">
            Cancelar
          </button>
          <button type="button" class="btn btn-success" @click="alterPass">Salvar</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import Breadcrumbs from '../Breadcrumbs.vue';
import { mapState, mapMutations, mapActions } from 'vuex';
import { Dropdown } from 'floating-vue';
import { auth } from '@/store/auth';
import Swal from 'sweetalert2';
export default {
  name: 'Navbar',
  // components: {
  //   Breadcrumbs,
  // },
  data() {
    return {
      showMenu: false,
      typePass: false,
      typePassConfim: false,
    };
  },
  computed: {
    ...mapState(['isRTL', 'isNavFixed', 'darkMode']),
    currentRouteName() {
      return this.$route.name;
    },
    currentDirectory() {
      let dir = this.$route.path.split('/')[1];
      return dir.charAt(0).toUpperCase() + dir.slice(1);
    },
    user() {
      const dados = auth();
      return dados.user.displayName;
    },
    isUser() {
      const dados = auth();
      return !dados.userLogged['funcionario'] && !dados.userLogged.administrador;
    },
  },
  created() {
    this.minNav;
  },
  beforeUpdate() {
    this.toggleNavigationOnMobile();
  },
  methods: {
    ...mapMutations(['navbarMinimize', 'toggleConfigurator']),
    ...mapActions(['toggleSidebarColor']),
    toggleNavigationOnMobile() {
      if (window.innerWidth < 1200) {
        this.navbarMinimize();
      }
    },
    logout() {
      this.$emit('logout', true);
    },
    alterPass() {
      if (this.newPassword.length < 8) {
        Swal.fire({
          icon: 'warning',
          title: 'Atenção',
          text: 'Senha deve ter no minimo 8 caracteres',
        });
        return;
      }

      if (!this.senhaValida(this.newPassword)) {
        Swal.fire({
          icon: 'warning',
          title: 'Atenção',
          text: 'Senha deve conter letras maiúsculas, minúsculas, números e caracteres especiais ([!|@|#|$|%|^|&|*|(|)|-|_])!',
        });
        return;
      }

      if (this.newPassword != this.newConfirmPassword) {
        Swal.fire({
          icon: 'warning',
          title: 'Atenção',
          text: 'Senha e Confirmar senha estão diferentes',
        });
        return;
      }

      this.$emit('novaSenha', this.newPassword);
      this.newConfirmPassword = '';
      this.newPassword = '';
    },
    senhaValida(p) {
      var retorno = false;
      var letrasMaiusculas = /[A-Z]/;
      var letrasMinusculas = /[a-z]/;
      var numeros = /[0-9]/;
      var caracteresEspeciais = /[!|@|#|$|%|^|&|*|(|)|-|_]/;
      if (p.length < 8) {
        return retorno;
      }
      var auxMaiuscula = 0;
      var auxMinuscula = 0;
      var auxNumero = 0;
      var auxEspecial = 0;
      for (var i = 0; i < p.length; i++) {
        if (letrasMaiusculas.test(p[i])) auxMaiuscula++;
        else if (letrasMinusculas.test(p[i])) auxMinuscula++;
        else if (numeros.test(p[i])) auxNumero++;
        else if (caracteresEspeciais.test(p[i])) auxEspecial++;
      }
      if (auxMaiuscula > 0) {
        if (auxMinuscula > 0) {
          if (auxNumero > 0) {
            if (auxEspecial) {
              retorno = true;
            }
          }
        }
      }
      return retorno;
    },
  },
};
</script>
<style scoped>
.menu-togle-btn {
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  border-radius: 100%;
  width: 45px;
  height: 45px;
  right: 35px;
  bottom: 25px;
  z-index: 9999;
  cursor: pointer;
  background: #fff;
  border-radius: 50%;
  margin-bottom: 10px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.16);
}
.text-black {
  color: #000000;
}
.menu-togle-btn:hover {
  transform: scale(1.2);
  transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
}
.menu-togle-ajuda {
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  border-radius: 100%;
  width: 45px;
  height: 45px;
  right: 35px;
  bottom: 600px;
  z-index: 9999;
  cursor: pointer;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.16);
}

.menu-togle-ajuda:hover {
  transform: scale(1.2);
  transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
  }

  70% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(0.95);
  }
}



</style>
