import { AxiosResponse } from 'axios'
import { get } from '../api'

import { ComunicadoVisitante } from '../models'

const controller = 'comunicadovisitante'


export async function GetAll(): Promise<ComunicadoVisitante[]> {
   const http = await get();
 const { data } = await http.get<ComunicadoVisitante[], AxiosResponse>(`${controller}/all`);
    return data;
}

export async function ListComunicados(): Promise<any[]> {
   const http = await get();
 const { data } = await http.get<any[], AxiosResponse>(`${controller}/permitidos`);
    return data;
}


export async function Get(id: number): Promise<ComunicadoVisitante> {
   const http = await get();
 const { data } = await http.get<ComunicadoVisitante, AxiosResponse>(`${controller}/${id}`);
    return data;
}


export async function Post(item: ComunicadoVisitante): Promise<ComunicadoVisitante> {
   const http = await get();
 const { data } = await http.post<ComunicadoVisitante, AxiosResponse>(`${controller}`, item);
    return data;
}


export async function Put(item: ComunicadoVisitante): Promise<ComunicadoVisitante> {
   const http = await get();
 const { data } = await http.put<ComunicadoVisitante, AxiosResponse>(`${controller}`, item);
    return data;
}

export async function Delete(id: number): Promise<ComunicadoVisitante> {
   const http = await get();
 const { data } = await http.delete<ComunicadoVisitante, AxiosResponse>(`${controller}/${id}`);
    return data;
}


export async function paginate(page: number = 1, size: number = 10, termoPesquisa?: string): Promise<ComunicadoVisitante[]> {
   const http = await get();
 const { data } = await http.get<ComunicadoVisitante[], AxiosResponse>(`${controller}/paginate?page=${page}&size=${size}${termoPesquisa ? `&termoPesquisa=${termoPesquisa}` : ""}`);
    return data;
}

export async function PostNovoVisitante(item: ComunicadoVisitante): Promise<ComunicadoVisitante> {
   const http = await get();
 const { data } = await http.post<ComunicadoVisitante, AxiosResponse>(`${controller}/novoVisitante`, item);
    return data;
}
