import { createApp } from "vue";
import { createPinia } from 'pinia'
import App from "./App.vue";
import store from "./store";
import router from "./router";
import print from 'vue3-print-nb'
import Vue3Signature from "vue3-signature"
import Mask from "maska";
import money from 'v-money3'
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import "./assets/css/global.css";
import VueTilt from "vue-tilt.js";
import VueSweetalert2 from "vue-sweetalert2";
import ArgonDashboard from "./argon-dashboard";
import Vue3FormWizard from 'vue3-form-wizard'
import 'vue3-form-wizard/dist/style.css'
// import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import vSelect from 'vue-select'
// import "vue-select-3/src/scss/vue-select.scss";
import { setupCalendar, Calendar, DatePicker } from 'v-calendar';
import 'v-calendar/dist/style.css'

import "@vueform/multiselect/themes/default.css";
import Multiselect from '@vueform/multiselect'
import VueTheMask, { mask } from 'vue-the-mask'
import Vue3Toasity, { type ToastContainerOptions } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'
import "vue3-treeview/dist/style.css";
import treeview from "vue3-treeview";
import PrimeVue from 'primevue/config';
 
// import Toast from "vue-toastification";
// import "vue-toastification/dist/index.css";
const options = {
    position: "top-right",
    timeout: 5000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    icon: true,
};
window['$'] = require('jquery');
const appInstance = createApp(App);
appInstance.use(createPinia());
appInstance.use(FloatingVue, { container: '.app-container' })
appInstance.use(print);
appInstance.use(store);
appInstance.use(router.router);
appInstance.use(VueTilt);
appInstance.use(VueSweetalert2);
appInstance.use(Toast, options);
appInstance.use(ArgonDashboard);
appInstance.use(money);
appInstance.use(Vue3Signature)
appInstance.use(Vue3FormWizard);
appInstance.component('VCalendar', Calendar)
appInstance.component('VDatePicker', DatePicker)
appInstance.component("Multiselect", Multiselect);
appInstance.use(VueTheMask);
appInstance.directive(mask)
appInstance.use(Mask);
appInstance.component("VSelect", vSelect);
appInstance.component("tree", treeview);

appInstance.use(PrimeVue);

// appInstance.use(Toast, options);
appInstance.mount("#app");


appInstance.directive('uppercase', {
    // Quando a diretiva é inserida no elemento
    mounted(el) {
      el.style.textTransform = 'uppercase';
    }
  });