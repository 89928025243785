
import { defineComponent, ref } from 'vue';
import { auth } from '@/store/auth';
import SmartHome from './SmartHome.vue';
import clientHome from './clientHome.vue';
const adm = ref<boolean>(false);
const client = ref<boolean>(false);

export default defineComponent({
  components: {
    SmartHome,
    clientHome,
  },
  data() {
    return {
      authStore: auth(),
      adm: adm,
      client: client,
    };
  },
  mounted() {
    const userLogado = this.authStore.getUserLogged;
    console.log('userLogado');
    console.log(userLogado);
    if (userLogado?.administrador != null) {
      adm.value = true;
    client.value = false;
    } else {
        client.value = true;
        adm.value = false;
    }
  },
 
});
