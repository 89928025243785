import { AxiosResponse } from 'axios'
import { get } from '../api'

import { TipoUsuario } from '../models/TipoUsuario';

const controller = 'tipo-usuario'


export async function GetAll(): Promise<TipoUsuario[]> {
   const http = await get();
 const { data } = await http.get<TipoUsuario[], AxiosResponse>(`${controller}/all`);
    return data;
}


export async function Get(id: number): Promise<TipoUsuario> {
   const http = await get();
 const { data } = await http.get<TipoUsuario, AxiosResponse>(`${controller}/${id}`);
    return data;
}


export async function Post(andar: TipoUsuario): Promise<TipoUsuario> {
   const http = await get();
 const { data } = await http.post<TipoUsuario, AxiosResponse>(`${controller}`, andar);
    return data;
}


export async function Put(andar: TipoUsuario): Promise<TipoUsuario> {
   const http = await get();
 const { data } = await http.put<TipoUsuario, AxiosResponse>(`${controller}`, andar);
    return data;
}

export async function Delete(id: number): Promise<TipoUsuario> {
   const http = await get();
 const { data } = await http.delete<TipoUsuario, AxiosResponse>(`${controller}/${id}`);
    return data;
}


export async function paginate(page: number = 1, size: number = 10, termoPesquisa?: string): Promise<TipoUsuario[]> {
   const http = await get();
 const { data } = await http.get<TipoUsuario[], AxiosResponse>(`${controller}/paginate?page=${page}&size=${size}${termoPesquisa ? `&termoPesquisa=${termoPesquisa}` : ""}`);
    return data;
}