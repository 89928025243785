import { defineStore } from 'pinia';
import * as storage from './storage';
import { get } from '@/core/api';
import * as AuthService from '@/core/services/AuthService';
import { esqueciSenha } from '@/core/services/UsuarioService';
import { Credential, Empreendimento, LoginResponse, Usuario } from '@/core/models';
import { auth as getAuth, hasFirebase } from '@/firebaseConfig';
import { signOut, Auth } from 'firebase/auth';
interface IProps {
  user: LoginResponse;
  token: string;
  firebase: any,
  empreendimento?: Empreendimento,
  menusPlano: any,
  userLogged?: Usuario
}

export const auth = defineStore('auth', {
  state: () =>
  ({
    user: { nome: '' },
    token: '',
    firebase: undefined,
    menusPlano: [],
    empreendimento: { funcionamentos: [], integradorZapi: {}, redeSociais: [] },
    userLogged: { midias: [], telefones: [], veiculos: [] }
  } as unknown as IProps),
  getters: {
    getMenus(state) {
      // const dados = storage.getLocalMenus() == "undefined" ? undefined : JSON.parse(storage.getLocalMenus());
      return state.menusPlano;
    },
    getUserLogged(state) {
      // const dados = storage.getLocalLogged() == "undefined" ? undefined : JSON.parse(storage.getLocalLogged());
      return state.userLogged;
    }
  },
  actions: {
    ActionDoLogin(payload: Credential) {
      return AuthService.login(payload).then((res) => {
        this.ActionSetToken(res.token);
        this.user = res;
        return res;
      });
    },

    async ActionGetProfile() {
      const http = await get();
      return http.post('profile').then((r) => this.ActionSetUser(r.data));
    },

    ActionForgotPassword(payload) {
      return esqueciSenha(payload).then((f) => {
        return f;
      });
    },

   async ActionValidateToken(payload) {
      const http = await get();
      const url = 'validateTokenReset';
      return http.post(url, payload).then((r) => r.data);
    },

   async ActionNewPassword(payload) {
    const http = await get();
      const url = 'newPassword';
      return http.post(url, payload).then((r) => r.data);
    },

    async ActionCheckToken() {
      try {
        const user = await AuthService.onAuthChanged();

        this.ActionSetToken(user.token);
        this.ActionSetUser(user.user);
        return user.token;
      } catch (error) {
        throw new Error('Token Inválido');
      }
    },
    ActionSetUser(payload) {
      this.user = payload;
    },

    ActionSetFirebase(payload) {
      this.firebase = payload;
    },

    ActionSetEmpreendimento(payload) {
      this.empreendimento = payload;
    },
    ActionSetMenus(payload) {
      this.menusPlano = payload;
      // // console.log(this.menusPlano)
      // storage.setLocalMenus(JSON.stringify(this.menusPlano));
    },
    ActionSetUserLogged(payload) {
      this.userLogged = payload;
    },
    ActionSetToken(payload) {
      storage.setLocalToken(payload);
      storage.setHeaderToken(payload);
      this.token = payload;
    },
    ActionSingnout() {
      storage.setHeaderToken('');
      storage.deleteLocalToken();
      storage.deleteLocalUsuario();
      storage.deleteLocalFirebase();
      storage.deleteLocalPlan();
      storage.deleteLocalLogged();
      storage.deleteLocalMenus();
      this.ActionSetUser({});
      this.ActionSetToken('');
      this.ActionSetMenus([]);
      this.ActionSetUserLogged(undefined);
      if (hasFirebase)
        signOut(getAuth);

    },


  },
});
