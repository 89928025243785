import { AxiosResponse } from 'axios'
import { get } from '../api'

import { Dashboard } from '../models'

const controller = 'dashboard'


// export async function GetAllAmbienteByMes(): Promise<Dashboard[]> {
//    const http = await get();
//  const { data } = await http.get<Dashboard[], AxiosResponse>(`${controller}/allAmbienteMes`);
//     return data;
// } 

// export async function GetAllAmbiente(): Promise<Dashboard[]> {
//    const http = await get();
//  const { data } = await http.get<Dashboard[], AxiosResponse>(`${controller}/allAmbiente`);
//     return data;
// }

// export async function GetAmbiente(id: number): Promise<Dashboard> {
//    const http = await get();
//  const { data } = await http.get<Dashboard, AxiosResponse>(`${controller}/${id}`);
//     return data;
// }

// export async function GetMidia(): Promise<Dashboard[]> {
//    const http = await get();
//  const { data } = await http.get<Dashboard[], AxiosResponse>(`${controller}/allMidia`);
//     return data;
// }


export async function GetAll(): Promise<Dashboard> {
   const http = await get();
 const { data } = await http.get<Dashboard, AxiosResponse>(`${controller}/all`);
    return data;
}

export async function DashboardClient(filtros?: any): Promise<any[]> {
    let query = new URLSearchParams(filtros).toString()
   const http = await get();
 const { data } = await http.get<Dashboard, AxiosResponse>(`${controller}/cliente${query ? '?' + query : ''}`);
    return data;
}
