
import { defineComponent, ref, watch, computed } from 'vue';
import ReportsDoughnutChart from '@/examples/Charts/ReportsDoughnutChart.vue';
import DoughnutChart from '@/examples/Charts/DoughnutChart.vue';
import ThinBarChart from '@/examples/Charts/ThinBarChart.vue';
import DefaultCounterCard from '@/examples/Cards/DefaultCounterCard.vue';
import PlaceholderCard from '@/examples/Cards/PlaceholderCard.vue';
import ControllerCard from '@/examples/Cards/ControllerCard.vue';
// import WeatherCard from "./components/WeatherCard.vue";
import Humidity from '@/components/Icons/Humidity.vue';
import Temperature from '@/components/Icons/Temperature.vue';
import Air from '../../components/Icons/Air.vue';
import Lights from '../../components/Icons/Lights.vue';
import Wifi from '../../components/Icons/Wifi.vue';
import { DashboardClient } from '@/core/services/DashboardService';
import { preloaderStore } from '@/store/preloaderStore';
import { ptBR } from 'date-fns/locale';
import { format } from 'date-fns';
import { GetMeusComunicados } from '@/core/services/ComunicadosService';
import { GetMinhasMercadorias } from '@/core/services/ComunicadosMercadoriaService';
const today = new Date();
const year = today.getFullYear();
const month = today.getMonth() + 1;
const daysInCurrentMonth = new Date(year, month, 0).getDate();
const filterAcessos = ref<any>({
  day: Array.from({ length: 24 }, (_, i) => i.toString().padStart(2, '0')),
  week: ['domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado'],
  month: ref(Array.from({ length: daysInCurrentMonth }, (_, i) => (i + 1).toString().padStart(2, '0'))),
  year: Array.from({ length: 12 }, (_, i) => (i + 1).toString().padStart(2, '0')),
});
const tipoMidia = ref<any[]>([]);
const usuarios = ref<any[]>([]);
const usuariosBlocos = ref<any[]>([]);
const Correspondencias = ref<any[]>([]);
const acessoMidas = ref<any[]>([]);
const acessoTipos = ref<any[]>([]);
const comunicados = ref<any[]>([]);
const acessoTiposEntrada = ref<any[]>([]);
const acessoBlocos = ref<any[]>([]);
const pontoAcesso = ref<any[]>([]);
const acessos = ref<any[]>([]);
const usuariosAcessos = ref<any[]>([]);
const acessosPorPeriodo = ref<any[]>([]);
const acessosPeriodo = ref<any[]>([]);
const acessosUsuario = ref<any[]>([]);
const acessosPorUsuario = ref<any[]>([]);
const ambienteAllMes = ref<any[]>([]);
const nomes = ref<any[]>([]);
const ambienteAll = ref<any[]>([]);
const filter = ref<any>({
  acessos: { label: 'Hoje', value: 'day' },
  blocos: { label: 'Hoje', value: 'day' },
  tipoAcesso: { label: 'Hoje', value: 'day' },
  entradaSaida: { label: 'Hoje', value: 'day' },
  acessosPorPeriodo: { label: 'Hoje', value: 'day' },
  acessosUsuario: format(new Date(), 'yyyy-MM-dd'),
});
const totalCondominos = ref<number>();
const totalCondominiosComMidia = ref<number>();

const intervalor = ref<any>();


 

function contador(lista, campo) {
  let contagens = {};
  let result;
  result = [];
  lista.forEach((objeto) => {
    if (contagens[objeto[`${campo}`]]) {
      contagens[objeto[`${campo}`]]++;
    } else {
      contagens[objeto[`${campo}`]] = 1;
    }
  });

  for (let name in contagens) {
    let objetoResultado = {
      nome: name,
      qtd: contagens[name],
    };
    result.push(objetoResultado);
  }
  console.log(campo);
  console.log(result);
  return result;
}

async function loadDash() {
  const loader = preloaderStore();
   DashboardClient().then((f: any) => {
    acessos.value = f['acessos'];

    acessos.value.map((a) => {
      a.dataText = format(new Date(a.dataHora), 'dd/MM/yyyy HH:mm');
      a.dia = format(new Date(a.dataHora), 'HH');
      a.semana = format(new Date(a.dataHora), 'EEEE', { locale: ptBR });
      a.mes = format(new Date(a.dataHora), 'dd');
      a.ano = format(new Date(a.dataHora), 'MM');

      if(!nomes.value.find((f)=>f == a.nome)){
        nomes.value.push(a.nome)
      }

    });
    console.log('acessos.value');
    console.log(f);
    console.log('nomes.value');
    console.log(nomes.value);
  });
  // .finally(() => {
  //   loader.setPreloader(false);
  // });
  atualizaEscopoGraficoPeriodo(filter.value.acessosPorPeriodo.value);

  GetMeusComunicados().then((f:any)=> {
    comunicados.value = f;
    comunicados.value.map((c)=>{
      c.criadoText = format(new Date(c.criado), 'dd/MM/yyyy');
      c.dataEventoText = format(new Date(c.dataEvento), 'dd/MM/yyyy HH:mm');
      if(c.dataEventoText.includes('00:00')){
        c.dataEventoText = c.dataEventoText.replace('00:00', '')
      }
    });
    console.log('comunicados.value')
    console.log(comunicados.value)
    })

  GetMinhasMercadorias().then((f:any)=> {
    Correspondencias.value = f;    
    console.log('Correspondencias.value')
    console.log(Correspondencias.value)
    })

   
}
function loadAcessosPeriodo(data: string) {
  DashboardClient({ data: format(new Date(), 'yyyy-MM-dd'), diaExato: true }).then((f: any) => {
    acessosPorPeriodo.value = f['acessos'];
    acessosPorPeriodo.value.map((a) => {
      a.dia = format(new Date(a.dataHora), 'HH');
      a.semana = format(new Date(a.dataHora), 'EEEE', { locale: ptBR });
      a.mes = format(new Date(a.dataHora), 'dd');
      a.ano = format(new Date(a.dataHora), 'MM');
    });
    console.log('acessosPorPeriodo.value');
    console.log(f);
  });
}
async function loadGraficosAcessos() {
  acessoMidas.value = await contador(acessos.value, 'tipoMidia');
  acessoBlocos.value = await contador(acessos.value, 'bloco');
  acessoTipos.value = await contador(acessos.value, 'tipoAcesso');
  acessoTiposEntrada.value = await contador(acessos.value, 'pontoAcesso');
}
async function atualizaEscopoGraficoPeriodo(time: string) {
  const DateSelected =
    time == 'day'
      ? new Date()
      : time == 'week'
      ? new Date(Date.now() - 6 * 24 * 60 * 60 * 1000)
      : time == 'month'
      ? new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
      : new Date(Date.now() -  12 * 30 * 24 * 60 * 60 * 1000);

  return DashboardClient({ data: format(new Date(DateSelected), 'yyyy-MM-dd'), diaExato: false }).then(async (f: any) => {
    acessosPorPeriodo.value = f['acessos'];
    acessosPorPeriodo.value.map((a) => {
      a.day = format(new Date(a.dataHora), 'HH');
      a.week = format(new Date(a.dataHora), 'EEEE', { locale: ptBR });
      a.month = format(new Date(a.dataHora), 'dd');
      a.year = format(new Date(a.dataHora), 'MM');
    });
    acessosPeriodo.value = await contador(acessosPorPeriodo.value, time);
    acessosPeriodo.value = filterAcessos.value[time].map((f)=>{
        console.log(f.toString())
        console.log(acessosPeriodo.value.find((x)=>x.nome == `${f}`)?.qtd )

      return {nome: f ,qtd: acessosPeriodo.value.find((x)=>x.nome == f.toString())?.qtd ?? 0}
    })
    console.log('acessosPorPeriodo.value');
    console.log(acessosPeriodo.value); 
    console.log(filterAcessos.value[time]); 
  });
}
async function atualizaEscopoGraficoUsuario(time: string) {
 

   DashboardClient({ data: format(new Date(`${time}T00:00:00`), 'yyyy-MM-dd'), diaExato: true }).then(async (f: any) => {
    acessosPorUsuario.value = f['acessos'];

    console.log(acessosPorUsuario.value)
    //filtrando pelo nome
    if(filter.value.user){
      console.log(filter.value.user)
      acessosPorUsuario.value  = acessosPorUsuario.value.filter((x)=>x.nome == filter.value.user)
    }
    console.log(acessosPorUsuario.value)

    //separando dia semana mes ano
      acessosPorUsuario.value.map((a) => {
      a.day = format(new Date(a.dataHora), 'HH');
      a.week = format(new Date(a.dataHora), 'EEEE', { locale: ptBR });
      a.month = format(new Date(a.dataHora), 'dd');
      a.year = format(new Date(a.dataHora), 'MM');
    });
    console.log(acessosPorUsuario.value)
    
    //contando ocorrencias
    acessosUsuario.value = await contador(acessosPorUsuario.value, 'day');
    console.log(acessosUsuario.value)

    // colocando os dias sem nada e associando o que tem
    acessosUsuario.value = filterAcessos.value['day'].map((f)=>{
        console.log(f.toString())
        console.log(acessosUsuario.value.find((x)=>x.nome == `${f}`)?.qtd )
      return {nome: f ,qtd: acessosUsuario.value.find((x)=>x.nome == f.toString())?.qtd ?? 0}
    }) 
    console.log(acessosUsuario.value)
  });
}





watch(
  () => filter.value.acessosPorPeriodo,
  () => {
    atualizaEscopoGraficoPeriodo(filter.value.acessosPorPeriodo.value);
  }
);
watch(
  () => filter.value.user,
  () => {
    atualizaEscopoGraficoUsuario(filter.value.acessosUsuario);
    }
);
watch(
  () => filter.value.acessosUsuario,
  () => {
    if(filter.value.user){

      atualizaEscopoGraficoUsuario(filter.value.acessosUsuario);
    }
    }
);

 
export default defineComponent({
  components: {
    DefaultCounterCard,
    ReportsDoughnutChart,
    DoughnutChart,
    ThinBarChart,
    PlaceholderCard,
    ControllerCard,
    Humidity,
    Temperature,
    Air,
    Lights,
    Wifi,
  },
  data() {
    return {
      midias: tipoMidia,
      totalCondominos: totalCondominos,
      ambientesMes: ambienteAllMes,
      ambientes: ambienteAll,
      acessosPorPeriodo: acessosPorPeriodo,
      acessoBlocos: acessoBlocos,
      usuarios: usuarios,
      usuariosBlocos: usuariosBlocos,
      totalCondominiosComMidia: totalCondominiosComMidia,
      filter: filter,
      acessoTipos: acessoTipos,
      Correspondencias: Correspondencias,
      comunicados: comunicados,
      filterAcessos: filterAcessos,
      acessos: acessos,
      acessoTiposEntrada: acessoTiposEntrada,
      acessosPeriodo: acessosPeriodo,
      acessosUsuario: acessosUsuario,
      nomes: nomes,
    };
  },
  mounted() {
    loadDash();
  },
  beforeUnmount() {
    clearInterval(intervalor.value);
  },
});
