// Import the functions you need from the SDKs you need
import { initializeApp, getApps, FirebaseApp } from "firebase/app";
import { getAuth } from 'firebase/auth'
import axios from 'axios'
import { Get } from './core/services/ConfigService'

let app;
let auth;

let hasFirebase;

// init().then(f => {
//   let firebaseConfig = f;
//   if (!getApps().length) {
//     app = initializeApp(firebaseConfig);
//   } else {
//     app = getApps()[0];
//   }
//   // Initialize Firebase


//   //initialize firebase auth
//   auth = getAuth()



// })

async function getApp(firebase) {

  if (!getApps().length) {
    const firebaseConfig = firebase;
    hasFirebase = !!firebaseConfig;

    if (hasFirebase) {
      app = initializeApp(JSON.parse(firebaseConfig));
      auth = getAuth()
    }

  } else {
    app = getApps()[0];
    auth = getAuth();

  }

  return hasFirebase;
}
export { app, auth, getApp, hasFirebase }