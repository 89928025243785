import { AxiosResponse } from 'axios'
import { get } from '../api'


const controller = 'menu'
export async function Get(): Promise<any[]> {
   const http = await get();
 const { data } = await http.get<any[], AxiosResponse>(`${controller}`);
    return data;
}
