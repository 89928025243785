<template>
  <aside
    id="sidenav-main"
    class="sidenav navbar navbar-vertical navbar-expand-xs border-0"
    :class="` ${sidebarType} ${isRTL ? 'fixed-end me-3 rotate-caret' : 'fixed-start '} ${
      layout === 'landing' ? 'bg-transparent shadow-none' : ''
    }`"
  >
    <div class="sidenav-header">
      <i
        id="iconSidenav"
        class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
        aria-hidden="true"
      ></i>
      <router-link class="m-0 navbar-brand" to="/">
        <transition mode="out-in">
       <div class="d-flex">
         <img src="../../assets/logoCR.png" class="navbar-brand-img h-100  " alt="main_logo" />
         <span class=" ms-1 mt-1 fs-6">Controle Rapido</span>
       </div>
           
    
        </transition>
      </router-link>
    </div>
    <hr class="mt-0 horizontal dark" />
    <sidenav-list />
  </aside>
</template>
<script>
import SidenavList from './SidenavList.vue';
import logo from '@/assets/img/logo-ct-dark.png';
import logoWhite from '@/assets/img/logo-ct.png';
import image from '@/assets/img/profile-layout-header.jpg';
import SidenavCollapse from './SidenavCollapse.vue';

import { mapState, mapMutations } from 'vuex';
export default {
  name: 'Index',
  components: {
    SidenavList,
  },
  data() {
    return {
      logo,
      logoWhite,
      image,
    };
  },
  methods: {
    ...mapMutations(['navbarMinimize', 'toggleConfigurator']),

    toggleNavigationOnMobile() {
      if (window.innerWidth < 1200) {
        this.navbarMinimize();
      }
    },
  },
  computed: {
    ...mapState(['layout', 'isRTL', 'isNavFixed', 'sidebarType', 'darkMode']),

    expanded() {
      const app = document.querySelector('#app');
      return app.classList.contains('g-sidenav-pinned');
    },
  },
  beforeUnmount() {
    this.$store.state.image;
  },
};
</script>

<style>
.sidenav  .logo1 {
  display: none;
}
.sidenav:hover .logo1 {
  display: block;
}
.sidenav .logo2 {
  display: block;
}
.sidenav:hover .logo2 {
  display: none;
}
.teste{}
</style>
