<script  setup>
import { ref, onMounted, onUnmounted } from 'vue'
import { loadingSystem } from '@/store/loadingSystemStore'
import LoaderIcon from './Icons/LoaderIcon.vue'
const preloader = loadingSystem()
</script>

<template>
    <div v-if="preloader.loading" class="preloader">
        <div class="text-center">
            <transition name="fade" mode="out-in">
                <p v-if="preloader.show" class="msg">Aguarde...</p>
                <p v-else class="msg">Estamos preparando o ambiente!</p>
            </transition>
            <p class="spinner-border" style="width: 3rem; height: 3rem;"></p>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.preloader {
    position: fixed;
    top: 0;
    background: #780404;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100%;
    z-index: 2549;
}

.msg {
    font-size: 72px;
    text-align: center;
    font-weight: 600;
}

/* Estilizando o elemento que terá a animação */


.fade-enter-active,
.fade-leave-active {
    transition: opacity .5s ease;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active for <2.1.8 */
    {
    opacity: 0;
}
</style>
