import { AxiosResponse } from 'axios'
import { get } from '../api'

import { Config } from '../models'
import { EnviaQrCode } from '../models/EnviaQrcode';
import { config } from 'floating-vue/dist/config';

const controller = 'config'

export async function Get(): Promise<Config> {
   const http = await get();
 const { data } = await http.get<Config, AxiosResponse>(`${controller}`);
    return data;
}

export async function saveMenu(menu: any): Promise<Config> {
   const http = await get();
 const { data } = await http.post<Config, AxiosResponse>(`${controller}/menu`, menu);
    return data;
}

export async function enviaQrCode(envia: EnviaQrCode): Promise<boolean> {
   const http = await get();
 const { data } = await http.post<boolean, AxiosResponse>(`${controller}/envia-qrcode`, envia);
    return data;
}
