import { setBearerToken } from "@/core/api";
// TOKEN
export const setHeaderToken = token => setBearerToken(token);
export const getLocalToken = () => localStorage.getItem("token");
export const deleteLocalToken = () => localStorage.removeItem("token");
export const setLocalToken = token => localStorage.setItem("token", token);

// USER
export const getLocalUsuario = () =>
  JSON.parse(localStorage.getItem("usuario"));
export const deleteLocalUsuario = () => localStorage.removeItem("usuario");
export const deleteLocalPlan = () => localStorage.removeItem("plan");
export const setLocalUsuario = usuario =>
  localStorage.setItem("usuario", usuario);

// Firebase

export const getLocalFirebase = () => localStorage.getItem("firebase");
export const deleteLocalFirebase = () => localStorage.removeItem("firebase");
export const setLocalFirebase = token => localStorage.setItem("firebase", token);


// Menus

export const getLocalMenus = () => localStorage.getItem("menu");
export const deleteLocalMenus = () => localStorage.removeItem("menu");
export const setLocalMenus = token => localStorage.setItem("menu", token);




// Logged

export const getLocalLogged = () => localStorage.getItem("Logged");
export const deleteLocalLogged = () => localStorage.removeItem("Logged");
export const setLocalLogged = token => localStorage.setItem("Logged", token);