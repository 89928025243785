import { AxiosResponse } from 'axios'
import { get } from '../api'

import { UsuarioMidia } from '../models'

const controller = 'usuariomidia'


export async function GetAll(): Promise<UsuarioMidia[]> {
   const http = await get();
   const { data } = await http.get<UsuarioMidia[], AxiosResponse>(`${controller}/all`);
   return data;
}


export async function Get(id: number): Promise<UsuarioMidia> {
   const http = await get();
   const { data } = await http.get<UsuarioMidia, AxiosResponse>(`${controller}/${id}`);
   return data;
}

export async function FindByRegistro(usuariomidia: string): Promise<UsuarioMidia> {
   const http = await get();
   const { data } = await http.get<UsuarioMidia, AxiosResponse>(`${controller}/reg/${usuariomidia}`);
   return data;
}

export async function Post(andar: UsuarioMidia): Promise<UsuarioMidia> {
   const http = await get();
   const { data } = await http.post<UsuarioMidia, AxiosResponse>(`${controller}`, andar);
   return data;
}


export async function Put(andar: UsuarioMidia): Promise<UsuarioMidia> {
   const http = await get();
   const { data } = await http.put<UsuarioMidia, AxiosResponse>(`${controller}`, andar);
   return data;
}

export async function Delete(id: number): Promise<UsuarioMidia> {
   const http = await get();
   const { data } = await http.delete<UsuarioMidia, AxiosResponse>(`${controller}/${id}`);
   return data;
}


export async function paginate(page: number = 1, size: number = 10, termoPesquisa?: string): Promise<UsuarioMidia[]> {
   const http = await get();
   const { data } = await http.get<UsuarioMidia[], AxiosResponse>(`${controller}/paginate?page=${page}&size=${size}${termoPesquisa ? `&termoPesquisa=${termoPesquisa}` : ""}`);
   return data;
}


export async function list(page: number = 1, size: number = 10, filters: any): Promise<any[]> {
   const http = await get();
   const { bloco, andar, unidade, termoPesquisado, usuario, usuariomidia } = filters;
   const { data } = await http.get<any[], AxiosResponse>(`${controller}/list?page=${page}&size=${size}${termoPesquisado ? `&termoPesquisado=${termoPesquisado}` : ""}${bloco ? `&blocoId=${bloco.id}` : ""}${andar ? `&andarId=${andar.id}` : ""}${unidade ? `&unidadeId=${unidade.id}` : ""}${usuariomidia ? `&usuariomidia=${usuariomidia}` : ""}${usuario ? `&usuarioId=${usuario.id}` : ""}`);
   return data;
}