import { AxiosResponse } from 'axios'
import { get } from '../api'

import { Unidade } from '../models'

const controller = 'unidade'

export async function GetAll(): Promise<Unidade[]> {
   const http = await get();
 const { data } = await http.get<Unidade[], AxiosResponse>(`${controller}/all`);

    return data;
}


export async function Get(id: number): Promise<Unidade> {
   const http = await get();
 const { data } = await http.get<Unidade, AxiosResponse>(`${controller}/${id}`);
    return data;
}


export async function Post(andar: Unidade): Promise<Unidade> {
   const http = await get();
 const { data } = await http.post<Unidade, AxiosResponse>(`${controller}`, andar);
    return data;
}


export async function Put(andar: Unidade): Promise<Unidade> {
   const http = await get();
 const { data } = await http.put<Unidade, AxiosResponse>(`${controller}`, andar);
    return data;
}

export async function Delete(id: number): Promise<Unidade> {
   const http = await get();
 const { data } = await http.delete<Unidade, AxiosResponse>(`${controller}/${id}`);
    return data;
}


export async function paginate(page: number = 1, size: number = 10, termoPesquisa?: string): Promise<Unidade[]> {
   const http = await get();
 const { data } = await http.get<Unidade[], AxiosResponse>(`${controller}/paginate?page=${page}&size=${size}${termoPesquisa ? `&termoPesquisa=${termoPesquisa}` : ""}`);
    return data;
}


export async function GetBloco(blocoId: number): Promise<Unidade[]> {
   const http = await get();
 const { data } = await http.get<Unidade[], AxiosResponse>(`${controller}/bloco/${blocoId}`);

    return data;
}
