
import { defineComponent, ref, watch } from 'vue';
import ReportsDoughnutChart from '@/examples/Charts/ReportsDoughnutChart.vue';
import DoughnutChart from '@/examples/Charts/DoughnutChart.vue';
import ThinBarChart from '@/examples/Charts/ThinBarChart.vue';
import DefaultCounterCard from '@/examples/Cards/DefaultCounterCard.vue';
import PlaceholderCard from '@/examples/Cards/PlaceholderCard.vue';
import ControllerCard from '@/examples/Cards/ControllerCard.vue';
// import WeatherCard from "./components/WeatherCard.vue";
import Humidity from '@/components/Icons/Humidity.vue';
import Temperature from '@/components/Icons/Temperature.vue';
import Air from '../../components/Icons/Air.vue';
import setNavPills from '@/assets/js/nav-pills.js';
import setTooltip from '@/assets/js/tooltip.js';
import Lights from '../../components/Icons/Lights.vue';
import Wifi from '../../components/Icons/Wifi.vue';
import { GetAll } from '@/core/services/DashboardService';
import { GetAll as GetCorrespondencias } from '@/core/services/ComunicadosMercadoriaService';
import { GetAll as GetPontoAcesso } from '@/core/services/PontoAcessoService';
import { FilterDashBoard } from '@/core/services/AcessoService';
import { GetAll as getAllUsers } from '@/core/services/UsuarioService';
import { preloaderStore } from '@/store/preloaderStore';
import Camera from './components/Camera.vue';
import { format } from 'date-fns';
import { auth } from '@/store/auth';

const tipoMidia = ref<any[]>([]);
const usuarios = ref<any[]>([]);
const usuariosBlocos = ref<any[]>([]);
const Correspondencias = ref<any[]>([]);
const acessoMidas = ref<any[]>([]);
const acessoTipos = ref<any[]>([]);
const acessoTiposEntrada = ref<any[]>([]);
const acessoBlocos = ref<any[]>([]);
const pontoAcesso = ref<any[]>([]);
const acessos = ref<any[]>([]);
const ambienteAllMes = ref<any[]>([]);
const ambienteAll = ref<any[]>([]);
const filter = ref<any>({
  midias: { label: 'Hoje', value: 'day' },
  blocos: { label: 'Hoje', value: 'day' },
  tipoAcesso: { label: 'Hoje', value: 'day' },
  entradaSaida: { label: 'Hoje', value: 'day' },
});
const totalCondominos = ref<number>();
const totalCondominiosComMidia = ref<number>();
 
const intervalor = ref<any>();

function contador(lista, campo) {
  let contagens = {};
  let result;
  result = [];
  lista.forEach((objeto) => {
    if (contagens[objeto[`${campo}`]]) {
      contagens[objeto[`${campo}`]]++;
    } else {
      contagens[objeto[`${campo}`]] = 1;
    }
  });

  for (let name in contagens) {
    let objetoResultado = {
      nome: name,
      qtd: contagens[name],
    };
    result.push(objetoResultado);
  }
  console.log(campo);
  console.log(result);
  return result;
}

async function loadDash() {
  const loader = preloaderStore();
  loader.setPreloader(true);
  GetAll()
    .then((f) => {
      console.log('f');
      console.log(f);
      tipoMidia.value = f.tipoMidia;
      ambienteAll.value = f.ambienteAll;
      ambienteAllMes.value = f.ambienteAllMes;
      totalCondominos.value = f.totalCondominios;
      totalCondominiosComMidia.value = f.totalCondominiosComMidia ?? 201;
    })
    .finally(() => {
      loader.setPreloader(false);
    });

  GetPontoAcesso().then((f) => {
    pontoAcesso.value = f.filter((x) => x.status);
    console.log(pontoAcesso.value);
  });

  await FilterDashBoard({ data: format(new Date(), 'yyyy-MM-dd'), posteriorData: true }).then((f) => {
    acessos.value = f;
    acessos.value.map((x) => {
      x.dataText = format(new Date(x.data), 'dd/MM/yyyy hh:mm');
    })
    acessos.value = acessos.value.filter((x) => !!x.bloco)
    console.log("acessos.value");
    console.log(acessos.value);
    loadGraficosAcessos();
  });

  getAllUsers().then(async (f) => {
    usuarios.value = f.filter((f) => !!f.tipoMorador);
    usuarios.value.map((f) => {
      f.aceiteLgpdText = f.aceiteLgpd ? 'Sim' : 'Não';
    });

    usuariosBlocos.value = await contador(
      usuarios.value.map((v) => {
        return { blocoX: v.unidade.andar.bloco.descricao };
      }),
      'blocoX'
    );
  });

  GetCorrespondencias().then((f) => {
    Correspondencias.value = f;
    Correspondencias.value.map((f) => {
      f.statusText = f.status ? 'Ativa' : 'Inativa';
      f.dataHoraText = format(new Date(f.dataHora), 'dd/MM/yyyy HH:mm:ss');
      f.andar = f.unidade?.andar;
      f.bloco = f.unidade?.andar?.bloco;
      f.entregue = f.entregue ? 'Sim' : 'Não';
    });

    Correspondencias.value = Correspondencias.value.sort((a, b) => a.criado - b.criado);
    console.log(Correspondencias.value);
  });
}
async function loadGraficosAcessos() {
  acessoMidas.value = await contador(acessos.value, 'tipoMidia');
  acessoBlocos.value = await contador(acessos.value, 'bloco');
  acessoTipos.value = await contador(acessos.value, 'tipoAcesso');
  acessoTiposEntrada.value = await contador(acessos.value, 'pontoAcesso');

  console.log('aqui')
  console.log(acessos.value)
}
async function atualizaEscopoGrafico(time: string, info: string) {
  const DateSelected = time == 'day' ? new Date() : time == 'week' ? new Date(Date.now() - 7 * 24 * 60 * 60 * 1000) : new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);

  return FilterDashBoard({ data: format(DateSelected, 'yyyy-MM-dd'), posteriorData: true }).then((f) => {
    f = f.filter((x) => !!x.bloco)
    return contador(f, info);
  });
}

watch(() => filter.value.midias,
  async () => {
    acessoMidas.value = await atualizaEscopoGrafico(filter.value.midias.value, 'tipoMidia')
  })
watch(() => filter.value.blocos,
  async () => {
    acessoBlocos.value = await atualizaEscopoGrafico(filter.value.blocos.value, 'bloco')
  })
watch(() => filter.value.tipoAcesso,
  async () => {
    acessoTipos.value = await atualizaEscopoGrafico(filter.value.tipoAcesso.value, 'tipoAcesso')
  })
watch(() => filter.value.entradaSaida,
  async () => {
    acessoTiposEntrada.value = await atualizaEscopoGrafico(filter.value.entradaSaida.value, 'pontoAcesso')
  })

export default defineComponent({
  components: {
    DefaultCounterCard,
    ReportsDoughnutChart,
    DoughnutChart,
    ThinBarChart,
    PlaceholderCard,
    ControllerCard,
    Humidity,
    Temperature,
    Air,
    Lights,
    Wifi,
    Camera,
  },
  data() {
    return {
      authStore:auth(),
      midias: tipoMidia,
      totalCondominos: totalCondominos,
      ambientesMes: ambienteAllMes,
      ambientes: ambienteAll,
      acessoMidas: acessoMidas,
      acessoBlocos: acessoBlocos,
      usuarios: usuarios,
      usuariosBlocos: usuariosBlocos,
      totalCondominiosComMidia: totalCondominiosComMidia,
      filter: filter,
      acessoTipos: acessoTipos,
      Correspondencias: Correspondencias,
      acessos: acessos,
      acessoTiposEntrada: acessoTiposEntrada, 
      
     };
  },
  mounted() {
    loadDash();
const userLogado = this.authStore.getUserLogged;
console.log("userLogado")
console.log(userLogado)
  },
  beforeUnmount() {
    clearInterval(intervalor.value);
  },
});
