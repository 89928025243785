import { AxiosResponse } from 'axios'
import { get } from '../api'

import { Administrador, Credential, LoginResponse, User } from '../models'

const controller = 'administrador'


export async function GetAll(): Promise<Administrador[]> {
 const http = await get();
 const { data } = await http.get<Administrador[], AxiosResponse>(`${controller}/all`);
  return data;
}


export async function Get(id: number): Promise<Administrador> {
 const http = await get();
 const { data } = await http.get<Administrador, AxiosResponse>(`${controller}/${id}`);
  return data;
}


export async function Post(andar: Administrador): Promise<Administrador> {
 const http = await get();
 const { data } = await http.post<Administrador, AxiosResponse>(`${controller}`, andar);
  return data;
}


export async function Put(andar: Administrador): Promise<Administrador> {
 const http = await get();
 const { data } = await http.put<Administrador, AxiosResponse>(`${controller}`, andar);
  return data;
}

export async function Delete(id: number): Promise<Administrador> {
 const http = await get();
 const { data } = await http.delete<Administrador, AxiosResponse>(`${controller}/${id}`);
  return data;
}


export async function paginate(page: number = 1, size: number = 10, termoPesquisa?: string): Promise<Administrador[]> {
 const http = await get();
 const { data } = await http.get<Administrador[], AxiosResponse>(`${controller}/paginate?page=${page}&size=${size}${termoPesquisa ? `&termoPesquisa=${termoPesquisa}` : ""}`);
  return data;
}

export async function Excluir(id: number): Promise<Administrador[]> {
  const http = await get();
  const { data } = await http.patch<Administrador[], AxiosResponse>(`${controller}/excluir/${id}`, {});
  return data;
}
