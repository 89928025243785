import { AxiosResponse } from 'axios'
import { get } from '../api'

import { Usuario } from '../models'


const controller = 'usuario'



export async function GetAll(): Promise<Usuario[]> {
   const http = await get();
   const { data } = await http.get<Usuario[], AxiosResponse>(`${controller}/all`);
   return data;
}


export async function Me(): Promise<Usuario> {
   const http = await get();
   const { data } = await http.get<Usuario, AxiosResponse>(`${controller}/me`);
   return data;
}


export async function GetUnidades(): Promise<Usuario[]> {
   const http = await get();
   const { data } = await http.get<Usuario[], AxiosResponse>(`${controller}/unidades-user`);
   return data;
}

export async function GetBloco(id: number): Promise<Usuario[]> {
   const http = await get();
   const { data } = await http.get<Usuario[], AxiosResponse>(`${controller}/bloco/${id}`);
   return data;
}

export async function GetAndar(id: number): Promise<Usuario[]> {
   const http = await get();
   const { data } = await http.get<Usuario[], AxiosResponse>(`${controller}/andar/${id}`);
   return data;
}

export async function GetUnidade(id: number): Promise<Usuario[]> {
   const http = await get();
   const { data } = await http.get<Usuario[], AxiosResponse>(`${controller}/unidade/${id}`);
   return data;
}
export async function Get(id: number): Promise<Usuario> {
   const http = await get();
   const { data } = await http.get<Usuario, AxiosResponse>(`${controller}/get/${id}`);
   return data;
}

export async function GetByToken(token: string): Promise<Usuario> {
   const http = await get();
   const { data } = await http.get<Usuario, AxiosResponse>(`${controller}/get-by-token/${token}`);
   return data;
}
export async function Post(andar: Usuario): Promise<Usuario> {
   const http = await get();
   const { data } = await http.post<Usuario, AxiosResponse>(`${controller}`, andar);
   return data;
}


export async function Put(andar: Usuario): Promise<Usuario> {
   const http = await get();
   const { data } = await http.put<Usuario, AxiosResponse>(`${controller}`, andar);
   return data;
}

export async function Delete(id: number): Promise<Usuario> {
   const http = await get();
   const { data } = await http.delete<Usuario, AxiosResponse>(`${controller}/${id}`);
   return data;
}


export async function list(page: number = 1, size: number = 10, { blocoId, andarId, unidadeId, termoPesquisado, placa, usuariomidia }: any): Promise<any[]> {
   const http = await get();
   const { data } = await http.get<any[], AxiosResponse>(`${controller}/list?page=${page}&size=${size}${termoPesquisado ? `&termoPesquisado=${termoPesquisado}` : ""}${blocoId ? `&blocoId=${blocoId}` : ""}${andarId ? `&andarId=${andarId}` : ""}${unidadeId ? `&unidadeId=${unidadeId}` : ""}${placa ? `&placa=${placa}` : ""}${usuariomidia ? `&usuariomidia=${usuariomidia}` : ""}`);
   return data;
}




export async function paginate(page: number = 1, size: number = 10, termoPesquisa?: string): Promise<Usuario[]> {
   const http = await get();
   const { data } = await http.get<Usuario[], AxiosResponse>(`${controller}/paginate?page=${page}&size=${size}${termoPesquisa ? `&termoPesquisa=${termoPesquisa}` : ""}`);
   return data;
}

export async function esqueciSenha(email: string): Promise<boolean> {
   const http = await get();
   const { data } = await http.post<boolean, AxiosResponse>(`${controller}/esqueci-senha`, email);
   return data;
}



export async function filter(filtros: any): Promise<Usuario[]> {
   let query = new URLSearchParams(filtros).toString()
   const http = await get();
   const { data } = await http.get<Usuario[], AxiosResponse>(`${controller}/filter?${query}`);
   return data;
}


export async function full(id: number): Promise<Usuario> {
   const http = await get();
   const { data } = await http.get<Usuario, AxiosResponse>(`${controller}/full/${id}`);
   return data;
}


export async function EnviarEmail(id: number): Promise<Usuario[]> {
   const http = await get();
   const { data } = await http.get<Usuario[], AxiosResponse>(`${controller}/enviarEmail/${id}`);
   return data;
}

export async function EnviarEmailALL(): Promise<Usuario[]> {
   const http = await get();
   const { data } = await http.get<Usuario[], AxiosResponse>(`${controller}/enviarEmailTodos`);
   return data;
}

export async function Excluir(id: number): Promise<Usuario[]> {
   const http = await get();
   const { data } = await http.patch<Usuario[], AxiosResponse>(`${controller}/excluir/${id}`, {});
   return data;
}