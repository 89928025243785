import { defineStore } from 'pinia';
interface IProps {
  loading: boolean;
  show: boolean;
  interval: any;
}
export const loadingSystem = defineStore('loadingSystem', {
  state: () =>
    ({
      loading: false,
      show: false,
      interval: null,
    } as IProps),
  actions: {
    setLoadingSystem(loading: boolean) {
      if (loading) {
        this.interval = setInterval(() => {
          this.show = !this.show;
          console.log('aqui');
        }, 3000);
      } else {
        console.log('aqui desmonta');
        clearInterval(this.interval);
      }
      this.loading = loading;
    },
  },
});
