import { defineStore } from 'pinia'
interface IProps {
    loading: boolean
}
export const preloaderStore = defineStore('preloaderStore', {
    state: () =>
    ({
        loading: false,
    } as IProps),
    actions: {
        setPreloader(loading) {
            this.loading = loading
        },
    },
})
