import { AxiosResponse } from 'axios'
import { get } from '../api'

import { PontoAcesso } from '../models'

const controller = 'ponto-acesso'


export async function GetAll(): Promise<PontoAcesso[]> {
   const http = await get();
 const { data } = await http.get<PontoAcesso[], AxiosResponse>(`${controller}/all`);
    return data;
}

export async function Leitores(): Promise<any[]> {
   const http = await get();
 const { data } = await http.get<any[], AxiosResponse>(`${controller}/leitores`);
    return data;
}


export async function Get(id: number): Promise<PontoAcesso> {
   const http = await get();
 const { data } = await http.get<PontoAcesso, AxiosResponse>(`${controller}/${id}`);
    return data;
}


export async function Post(andar: PontoAcesso): Promise<PontoAcesso> {
   const http = await get();
 const { data } = await http.post<PontoAcesso, AxiosResponse>(`${controller}`, andar);
    return data;
}


export async function Put(andar: PontoAcesso): Promise<PontoAcesso> {
   const http = await get();
 const { data } = await http.put<PontoAcesso, AxiosResponse>(`${controller}`, andar);
    return data;
}

export async function Delete(id: number): Promise<PontoAcesso> {
   const http = await get();
 const { data } = await http.delete<PontoAcesso, AxiosResponse>(`${controller}/${id}`);
    return data;
}


export async function paginate(page: number = 1, size: number = 10, termoPesquisa?: string): Promise<PontoAcesso[]> {
   const http = await get();
 const { data } = await http.get<PontoAcesso[], AxiosResponse>(`${controller}/paginate?page=${page}&size=${size}${termoPesquisa ? `&termoPesquisa=${termoPesquisa}` : ""}`);
    return data;
}



export async function FindByUser(id: number): Promise<PontoAcesso[]> {
   const http = await get();
 const { data } = await http.get<PontoAcesso[], AxiosResponse>(`${controller}/usuario/${id}`);
    return data;
}

export async function list(page: number = 1, size: number = 10, { blocoId, andarId, unidadeId, termoPesquisado, placa, usuariomidia ,startDate, endDate}: any): Promise<any[]> {
   const http = await get();
   const { data } = await http.get<any[], AxiosResponse>(`${controller}/list?page=${page}&size=${size}${termoPesquisado ? `&termoPesquisado=${termoPesquisado}` : ""}${blocoId ? `&blocoId=${blocoId}` : ""}${andarId ? `&andarId=${andarId}` : ""}${unidadeId ? `&unidadeId=${unidadeId}` : ""}${placa ? `&placa=${placa}` : ""}${usuariomidia ? `&usuariomidia=${usuariomidia}` : ""}${startDate ? `&initialDate=${startDate}` : ""}${endDate ? `&endDate=${endDate}` : ""}`);
   return data;
 }