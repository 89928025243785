import { AxiosResponse } from 'axios'
import { get } from '../api'

import { Empreendimento } from '../models'

const controller = 'empreendimento'


export async function GetAll(): Promise<Empreendimento[]> {
 const http = await get();
 const { data } = await http.get<Empreendimento[], AxiosResponse>(`${controller}/all`);
  return data;
}

export async function Only(): Promise<Empreendimento> {
 const http = await get();
 const { data } = await http.get<Empreendimento, AxiosResponse>(`${controller}/only`);
  return data;
}


export async function Get(id: number): Promise<Empreendimento> {
 const http = await get();
 const { data } = await http.get<Empreendimento, AxiosResponse>(`${controller}/${id}`);
  return data;
}


export async function Post(andar: Empreendimento): Promise<Empreendimento> {
 const http = await get();
 const { data } = await http.post<Empreendimento, AxiosResponse>(`${controller}`, andar);
  return data;
}


export async function Put(andar: Empreendimento): Promise<Empreendimento> {
 const http = await get();
 const { data } = await http.put<Empreendimento, AxiosResponse>(`${controller}`, andar);
  return data;
}

export async function Delete(id: number): Promise<Empreendimento> {
 const http = await get();
 const { data } = await http.delete<Empreendimento, AxiosResponse>(`${controller}/${id}`);
  return data;
}


export async function paginate(page: number = 1, size: number = 10, termoPesquisa?: string): Promise<Empreendimento[]> {
 const http = await get();
 const { data } = await http.get<Empreendimento[], AxiosResponse>(`${controller}/paginate?page=${page}&size=${size}${termoPesquisa ? `&termoPesquisa=${termoPesquisa}` : ""}`);
  return data;
}
