import { AxiosResponse } from 'axios'
import { get } from '../api'

import { Acesso } from '../models'
import { AcessoManual } from '../models/AcessoManual';

const controller = 'acesso'




export async function Manual(acesso: AcessoManual): Promise<AcessoManual> {
 const http = await get();
 const { data } = await http.post<AcessoManual, AxiosResponse>(`${controller}/manual`, acesso);
  return data;
}


export async function Ip(): Promise<Acesso[]> {

 const http = await get();
 const { data } = await http.get<Acesso[], AxiosResponse>(`ip`);
  return data;
}
export async function GetAll(): Promise<Acesso[]> {

 const http = await get();
 const { data } = await http.get<Acesso[], AxiosResponse>(`${controller}/all`);
  return data;
}


export async function filter(filtros: any): Promise<any[]> {

  let query = new URLSearchParams(filtros).toString()
 const http = await get();
 const { data } = await http.get<any[], AxiosResponse>(`${controller}/filter?${query}`);
  return data;
}
export async function FilterDashBoard(filtros: any): Promise<any[]> {
  let query = new URLSearchParams(filtros).toString()
 const http = await get();
 const { data } = await http.get<any[], AxiosResponse>(`${controller}/filterDashBoard?${query}`);
  return data;
}



export async function Get(id: number): Promise<Acesso> {

 const http = await get();
 const { data } = await http.get<Acesso, AxiosResponse>(`${controller}/${id}`);
  return data;
}


export async function Post(andar: Acesso): Promise<Acesso> {

 const http = await get();
 const { data } = await http.post<Acesso, AxiosResponse>(`${controller}`, andar);
  return data;
}


export async function Put(andar: Acesso): Promise<Acesso> {

 const http = await get();
 const { data } = await http.put<Acesso, AxiosResponse>(`${controller}`, andar);
  return data;
}

export async function Delete(id: number): Promise<Acesso> {

 const http = await get();
 const { data } = await http.delete<Acesso, AxiosResponse>(`${controller}/${id}`);
  return data;
}


export async function paginate(page: number = 1, size: number = 10, termoPesquisa?: string): Promise<Acesso[]> {

 const http = await get();
 const { data } = await http.get<Acesso[], AxiosResponse>(`${controller}/paginate?page=${page}&size=${size}${termoPesquisa ? `&termoPesquisa=${termoPesquisa}` : ""}`);
  return data;
}





