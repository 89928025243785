import { AxiosResponse } from 'axios'
import { get } from '../api'

import { Midia } from '../models'

const controller = 'midia'



export async function GetAll(): Promise<Midia[]> {
   const http = await get();
 const { data } = await http.get<Midia[], AxiosResponse>(`${controller}/all`);
    return data;
}


export async function Get(id: number): Promise<Midia> {
   const http = await get();
 const { data } = await http.get<Midia, AxiosResponse>(`${controller}/${id}`);
    return data;
}


export async function Post(andar: Midia): Promise<Midia> {
   const http = await get();
 const { data } = await http.post<Midia, AxiosResponse>(`${controller}`, andar);
    return data;
}


export async function Put(andar: Midia): Promise<Midia> {
   const http = await get();
 const { data } = await http.put<Midia, AxiosResponse>(`${controller}`, andar);
    return data;
}

export async function Delete(id: number): Promise<Midia> {
   const http = await get();
 const { data } = await http.delete<Midia, AxiosResponse>(`${controller}/${id}`);
    return data;
}


export async function paginate(page: number = 1, size: number = 10, termoPesquisa?: string): Promise<Midia[]> {
   const http = await get();
 const { data } = await http.get<Midia[], AxiosResponse>(`${controller}/paginate?page=${page}&size=${size}${termoPesquisa ? `&termoPesquisa=${termoPesquisa}` : ""}`);
    return data;
}

export async function GetQrCode(): Promise<string> {
   const http = await get();
 const { data } = await http.get<string, AxiosResponse>(`${controller}/Random`);
    return data;
}



export async function filter(filtros: any): Promise<Midia[]> {
   let query = new URLSearchParams(filtros).toString()
   const http = await get();
   const { data } = await http.get<Midia[], AxiosResponse>(`${controller}/filter?${query}`);
   return data;
}


export async function full(id: number): Promise<Midia> {
   const http = await get();
   const { data } = await http.get<Midia, AxiosResponse>(`${controller}/full/${id}`);
   return data;
}
