import { AxiosResponse } from 'axios'
import { get } from '../api'

import { Fabricante } from '../models'

const controller = 'fabricante'

export async function GetAll(): Promise<Fabricante[]> {
   const http = await get();
 const { data } = await http.get<Fabricante[], AxiosResponse>(`${controller}/all`);
    return data;
}


export async function Get(id: number): Promise<Fabricante> {
   const http = await get();
 const { data } = await http.get<Fabricante, AxiosResponse>(`${controller}/${id}`);
    return data;
}


export async function Post(andar: Fabricante): Promise<Fabricante> {
   const http = await get();
 const { data } = await http.post<Fabricante, AxiosResponse>(`${controller}`, andar);
    return data;
}


export async function Put(andar: Fabricante): Promise<Fabricante> {
   const http = await get();
 const { data } = await http.put<Fabricante, AxiosResponse>(`${controller}`, andar);
    return data;
}

export async function Delete(id: number): Promise<Fabricante> {
   const http = await get();
 const { data } = await http.delete<Fabricante, AxiosResponse>(`${controller}/${id}`);
    return data;
}


export async function paginate(page: number = 1, size: number = 10, termoPesquisa?: string): Promise<Fabricante[]> {
   const http = await get();
 const { data } = await http.get<Fabricante[], AxiosResponse>(`${controller}/paginate?page=${page}&size=${size}${termoPesquisa ? `&termoPesquisa=${termoPesquisa}` : ""}`);
    return data;
}
