import { HttpTransportType, HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import axios from "axios";
import { defineStore } from "pinia";

interface IProps {
    connection?: HubConnection;
    isConnected: boolean;
    connectionId?: string;
}

export const socket = defineStore('socket', {
    state: () =>
    ({
        isConnected: false,
        connection: undefined,
    } as IProps),
    actions: {
        async connect() {
            let url = process.env.VUE_APP_WEB_SOCKET as string;
            if (!process.env.VUE_APP_WEB_SOCKET) {
                url = (await (axios.get(__dirname + "config.json"))).data.VUE_APP_WEB_SOCKET as string;
            }
            const con =  new HubConnectionBuilder().configureLogging(LogLevel.Debug).withUrl(url, {
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets
            }).build();
            this.connection = await con;
            await this.connection?.start();
            this.isConnected = true;
        },

        async get(): Promise<HubConnection> {
            if (!this.connection) {
                await this.connect();
            }

            return this.connection as HubConnection;
        }
    }

});