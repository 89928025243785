import { AxiosResponse } from 'axios'
import { get } from '../api'

import { Agenda } from '../models'

const controller = 'agenda'


export async function GetAll(): Promise<Agenda[]> {
   const http = await get();
 const { data } = await http.get<Agenda[], AxiosResponse>(`${controller}/all`);
    return data;
}


export async function Get(id: number): Promise<Agenda> {
   const http = await get();
 const { data } = await http.get<Agenda, AxiosResponse>(`${controller}/${id}`);
    return data;
}


export async function Post(andar: Agenda): Promise<Agenda> {
   const http = await get();
 const { data } = await http.post<Agenda, AxiosResponse>(`${controller}`, andar);
    return data;
}


export async function Put(andar: Agenda): Promise<Agenda> {
   const http = await get();
 const { data } = await http.put<Agenda, AxiosResponse>(`${controller}`, andar);
    return data;
}

export async function Delete(id: number): Promise<Agenda> {
   const http = await get();
 const { data } = await http.delete<Agenda, AxiosResponse>(`${controller}/${id}`);
    return data;
}


export async function paginate(page: number = 1, size: number = 10, termoPesquisa?: string): Promise<Agenda[]> {
   const http = await get();
 const { data } = await http.get<Agenda[], AxiosResponse>(`${controller}/paginate?page=${page}&size=${size}${termoPesquisa ? `&termoPesquisa=${termoPesquisa}` : ""}`);
    return data;
}