import { getDownloadURL, getStorage, ref, uploadString } from 'firebase/storage'
import { auth } from '@/store/auth'
import { Get } from '@/core/services/ConfigService';
import { getApp } from "@/firebaseConfig";
async function storeImage(file: File[] | File, folder: string): Promise<string | string[]> {
    const authStore = auth();
    if (!authStore.firebase) {
        var firebase = (await Get()).firebase;
        await getApp(firebase);
        authStore.ActionSetFirebase(firebase);

    }
    const storage = getStorage();
    if (Array.isArray(file)) {
        const links: string[] = [];

        for (const f of file) {

            const fil = f as File;
            const storageRef = ref(
                storage,
                `${folder}/${fil.name.split(" ")[0]}_${new Date().getTime()}`
            );

            links.push(await uploadString(storageRef, await convertBase64(fil), "data_url", {
                contentType: fil.type,
            }).then(() => {
                return getDownloadURL(storageRef);
            }));
        }
        return links;

    } else {

        let name = "";
        let ext = "";
        if (file.name.split(".").length > 2) {

            var apx = file.name.split(".");
            ext = JSON.parse(JSON.stringify(apx[apx.length - 1]));
            apx.pop();
            name = apx.join(".");
        } else {
            name = file.name.split(".")[0];
            ext = file.name.split(".")[2]
        }

        const storageRef = ref(
            storage,
            `${folder}/${name}_${new Date().getTime()}.${ext}`
        );

        return await uploadString(storageRef, await convertBase64(file), "data_url", {
            contentType: file.type,
        }).then(() => {
            return getDownloadURL(storageRef);
        });
    }




};

async function storeImageBase64(file: string[] | string, filename: string, folder: string): Promise<string | string[]> {
    const authStore = auth();
    if (!authStore.firebase) {
        var firebase = (await Get()).firebase;
        await getApp(firebase);
        authStore.ActionSetFirebase(firebase);

    }
    const storage = getStorage();
    if (Array.isArray(file)) {
        const links: string[] = [];

        for (const f of file) {


            const storageRef = ref(
                storage,
                `${folder}/${filename.split(" ")[0]}_${new Date().getTime()}`
            );

            links.push(await uploadString(storageRef, f, "data_url", {
                contentType: f.split(",")[0].split(";")[0].split(":")[1],
            }).then(() => {
                return getDownloadURL(storageRef);
            }));
        }
        return links;

    } else {
        const storageRef = ref(
            storage,
            `${folder}/${filename}_${new Date().getTime()}`
        );

        return await uploadString(storageRef, file, "data_url", {
            contentType: file.split(",")[0].split(";")[0].split(":")[1],
        }).then(() => {
            return getDownloadURL(storageRef);
        });
    }




};

const convertBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
            if (typeof fileReader.result == "string") {
                resolve(fileReader.result);
            }
        };
    });
};

export { storeImage, convertBase64, storeImageBase64 }