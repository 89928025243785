import type { RouteRecordRaw } from 'vue-router'

import { createRouter, createWebHistory } from "vue-router";
// import Landing from "../views/dashboards/Landing.vue";
// import Default from "../views/dashboards/Default.vue";
// import Automotive from "../views/dashboards/Automotive.vue";
import SmartHome from "../views/dashboards/SmartHome.vue";
import clientHome from "../views/dashboards/clientHome.vue";
import dashboard from "../views/dashboards/Dashboard.vue";
// import VRDefault from "../views/dashboards/vr/VRDefault.vue";
// import VRInfo from "../views/dashboards/vr/VRInfo.vue";
// import CRM from "../views/dashboards/CRM.vue";
// import Overview from "../views/pages/profile/Overview.vue";
// import Teams from "../views/pages/profile/Teams.vue";
// import Projects from "../views/pages/profile/Projects.vue";
// import General from "../views/pages/projects/General.vue";
// import Timeline from "../views/pages/projects/Timeline.vue";
// import NewProject from "../views/pages/projects/NewProject.vue";
// import Pricing from "../views/pages/Pricing.vue";
// import RTL from "../views/pages/Rtl.vue";
// import Charts from "../views/pages/Charts.vue";
// import SweetAlerts from "../views/pages/SweetAlerts.vue";
// import Notifications from "../views/pages/Notifications.vue";
// import Kanban from "../views/applications/Kanban.vue";
// import Wizard from "../views/applications/wizard/Wizard.vue";
// import DataTables from "../views/applications/DataTables.vue";
// import Calendar from "../views/applications/Calendar.vue";
// import Analytics from "../views/applications/analytics/Analytics.vue";
// import EcommerceOverview from "../views/ecommerce/overview/Overview.vue";
// import NewProduct from "../views/ecommerce/products/NewProduct.vue";
// import EditProduct from "../views/ecommerce/EditProduct.vue";
// import ProductPage from "../views/ecommerce/ProductPage.vue";
// import ProductsList from "../views/ecommerce/ProductsList.vue";
// import OrderDetails from "../views/ecommerce/Orders/OrderDetails";
// import OrderList from "../views/ecommerce/Orders/OrderList";
// import Referral from "../views/ecommerce/Referral";
// import Reports from "../views/pages/Users/Reports.vue";
// import NewUser from "../views/pages/Users/NewUser.vue";
// import Settings from "../views/pages/Account/Settings.vue";
// import Billing from "../views/pages/Account/Billing.vue";
// import Invoice from "../views/pages/Account/Invoice.vue";
// import Security from "../views/pages/Account/Security.vue";
// import Widgets from "../views/pages/Widgets.vue";
// import Basic from "../views/auth/signin/Basic.vue";
// import Cover from "../views/auth/signin/Cover.vue";
// import Illustration from "../views/auth/signin/Illustration.vue";
// import ResetBasic from "../views/auth/reset/Basic.vue";
// import ResetCover from "../views/auth/reset/Cover.vue";
// import ResetIllustration from "../views/auth/reset/Illustration.vue";
// import VerificationBasic from "../views/auth/verification/Basic.vue";
// import VerificationCover from "../views/auth/verification/Cover.vue";
// import VerificationIllustration from "../views/auth/verification/Illustration.vue";
// import SignupBasic from "../views/auth/signup/Basic.vue";
// import SignupCover from "../views/auth/signup/Cover.vue";
// import SignupIllustration from "../views/auth/signup/Illustration.vue";
// import Error404 from "../views/auth/error/Error404.vue";
// import Error500 from "../views/auth/error/Error500.vue";
// import lockBasic from "../views/auth/lock/Basic.vue";
// import lockCover from "../views/auth/lock/Cover.vue";
// import lockIllustration from "../views/auth/lock/Illustration.vue";

import License from "../views/license/License.vue";
import Auth from "../views/authenticate/Auth.vue";
import Blocos from "../views/pages/bloco/Blocos.vue";
import Andar from "../views/pages/andar/Andares.vue";
import Unidades from "../views/pages/unidade/Unidades.vue";
import Comunicados from "../views/pages/comunicado/Comunicados.vue";
import Correspondencias from "../views/pages/correspondencia/Correspondencias.vue";
import Equipamento from "../views/pages/equipamento/Equipamento.vue";
import Departamentos from "../views/pages/departamentos/Departamentos.vue";
import Funcionarios from "../views/pages/funcionarios/Funcionarios.vue";
import Administradores from "../views/pages/administradores/Administradores.vue";
import PortariaNovo from "../views/pages/portariaNovo/PortariaNovo.vue";
import ConsultaAcessos from "../views/pages/consultaAcessos/ConsultaAcessos.vue";
import Usuarios from "../views/pages/usuarios/Usuarios.vue";
import LogsAcesso from "../views/pages/logsAcesso/LogsAcesso.vue";
import Empresas from "../views/pages/empresas/Empresas.vue";
import Produtos from "../views/pages/produtos/Produtos.vue";
import Fornecedores from "../views/pages/fornecedores/Fornecedores.vue";
import Ambientes from "../views/pages/ambientes/Ambientes.vue";
import PontosAcesso from "../views/pages/pontosAcesso/PontosAcesso.vue";
import Controladora from "../views/pages/controladora/Controladora.vue";
import CameraIp from "../views/pages/cameraip/CameraIp.vue";
import Agenda from "../views/pages/agenda/Agenda.vue";
import MeusDados from "../views/pages/meusDados/MeusDados.vue";
import Dependentes from "../views/pages/dependentes/Dependentes.vue";
import Alertas from "../views/pages/alertas/Alertas.vue";
import ControleEstoque from "../views/pages/controleEstoque/ControleEstoque.vue";
import ComunicadoVisita from "../views/pages/comunicadoVisita/ComunicadoVisita.vue";
import Empreendimento from '@/views/pages/empreendimento/Empreendimento.vue';
import Bloqueado from "../views/pages/bloqueado/Bloqueado.vue";
import PerfilAdministrador from "../views/pages/perfilAdministrador/PerfilAdministrador.vue";
import TipoVisitante from '../views/pages/tipoVisitante/TipoVisitante.vue'
import ConsultaUsuarios from '../views/pages/ConsultaUsuarios/ConsultaUsuarios.vue';
import RelatorioUsuario from '../views/pages/RelatorioUsuario/RelatorioUsuario.vue';
import RelatorioAcesso from '../views/pages/relatorioAcesso/RelatorioAcesso.vue';
import RelatorioAcessoVisitante from '../views/pages/relatorioAcessoVisitante/RelatorioAcessoVisitante.vue';
import RelatorioMidia from '../views/pages/relatorioMidia/RelatorioMidia.vue';
import RelatorioTipoUsuario from '../views/pages/relatorioTipoUsuario/RelatorioTipoUsuario.vue';
import TipoUsuario from '../views/pages/tipoUsuario/TipoUsuario.vue'
import GestaoMenu from '../views/pages/gestaoMenu/GestaoMenu.vue'
import Convite from '../views/pages/convite/Convite.vue'
import ConvidadoGerado from '../views/pages/convidadoGerado/ConvidadoGerado.vue'
import Monitoramento from '../views/pages/Monitorar/Monitoramento.vue'
import Permissionamento from '../views/pages/Permissionar/permissionamento.vue'

const routes: RouteRecordRaw[] = [
  {
    path: '/bloqueado',
    name: 'Bloqueado',
    component: Bloqueado,
  },
  {
    path: '/comunicado-visita',
    name: 'Comunicado Visita',
    component: ComunicadoVisita,
    meta: {
      icon: 'fa-solid fa-cubes-stacked',
      grup: "Gerenciamento"
    }
  },
  {
    path: '/controle-estoque',
    name: 'Controle Estoque',
    component: ControleEstoque,
    meta: {
      icon: 'fa-solid fa-cubes-stacked',
      grup: "Gerenciamento"
    }
  },
  {
    path: '/monitoramento',
    name: 'Monitoramento acesso',
    component: Monitoramento,
    meta: {
      grup: "Gerenciamento"
    }
  },
  {
    path: '/alertas',
    name: 'Alertas',
    component: Alertas,
    meta: {
      icon: 'fa-solid fa-bell',
      grup: "Gerenciamento"
    }
  },
  {
    path: '/dependentes',
    name: 'Dependentes',
    component: Dependentes,
    meta: {
      icon: 'fa-solid fa-people-roof',
      grup: ""
    }
  },
  {
    path: '/meus-dados',
    name: 'Minha conta',
    component: MeusDados,
    meta: {
      icon: "fa-solid fa-circle-user",
    }
  },
  {
    path: '/agendamento',
    name: 'Agenda',
    component: Agenda,
    meta: {
      icon: 'fa-solid fa-calendar-days',
      grup: "Gerenciamento"
    }
  },
  {
    path: '/permissionamento',
    name: 'Permissionamento',
    component: Permissionamento,
    meta: {
      icon: 'fa-solid fa-check',
      grup: "Gerenciamento"
    }
  },
  {
    path: '/controladora',
    name: 'Controladora',
    component: Controladora,
    meta: {
      icon: 'fa-solid fa-book',
      grup: "Técnico"
    }
  },
  {
    path: '/pontos-de-acesso',
    name: 'Pontos de Acesso',
    component: PontosAcesso,
    meta: {
      icon: 'fa-solid fa-truck-fast',
      grup: "Gerenciamento"
    }
  },
  {
    path: '/ambientes',
    name: 'Ambientes',
    component: Ambientes,
    meta: {
      icon: 'fa-solid fa-location-dot',
      grup: "Cadastros"
    }
  },
  {
    path: '/fornecedores', 
    name: 'Fornecedores',
    component: Fornecedores,
    meta: {
      icon: 'fa-solid fa-truck-field',
      grup: "Cadastros"
    }
  },
  {
    path: '/produtos',
    name: 'Produtos',
    component: Produtos,
    meta: {
      icon: 'fa-brands fa-product-hunt',
      grup: "Gerenciamento"
    }
  },
  {
    path: '/empresas',
    name: 'Empresas',
    component: Empresas,
    meta: {
      icon: "fa-regular fa-building",
      grup: "Cadastros"
    }
  },
  {
    path: '/portaria',
    name: 'Portaria',
    component: PortariaNovo,
    meta: {
      icon: 'fa-solid fa-image-portrait',
      grup: "Gerenciamento"
    }
  },
  {
    path: '/consulta-acessos',
    name: 'Consulta Acessos',
    component: ConsultaAcessos,
    meta: {
      icon: "fa-solid fa-elevator",
      grup: "Relatórios"
    }
  },
  {
    path: '/administradores',
    name: 'Administradores',
    component: Administradores,
    meta: {
      icon: "fa-solid fa-user-secret",
      grup: "Cadastros"
    }
  },
  {
    path: '/usuarios',
    name: 'Usuários',
    component: Usuarios,
    meta: {
      icon: 'fa-solid fa-user-plus',
      grup: "Cadastros"
    }
  },
  {
    path: '/logs-acesso',
    name: 'Logs Acesso',
    component: LogsAcesso,
    meta: {
      icon: 'fa-solid fa-history',
      grup: "Técnico"
    }
  },
  {
    path: '/funcionarios',
    name: 'Funcionários',
    component: Funcionarios,
    meta: {
      icon: "fa-solid fa-person-walking-arrow-right",
      grup: "Cadastros"
    }
  },
  {
    path: '/departamentos',
    name: 'Departamentos',
    component: Departamentos,
    meta: {
      icon: "fa-solid fa-toolbox",
      grup: "Cadastros"
    }
  },
  {
    path: '/equipamento',
    name: 'Equipamento',
    component: Equipamento,
    meta: {
      icon: "fa-solid fa-wrench",
      grup: "Gerenciamento"
    }
  },
  {
    path: '/correspondencias',
    name: 'Correspondências',
    component: Correspondencias,
    meta: {
      icon: "fa fa-envelope-open-o",
      grup: "Condomínio"
    }
  },
  {
    path: '/comunicados',
    name: 'Comunicados',
    component: Comunicados,
    meta: {
      icon: 'fa-solid fa-book',
      grup: "Condomínio"
    }

  },
  {
    path: '/andares',
    name: 'Andares',
    component: Andar,
    meta: {
      icon: "fa-solid fa-building-user",
      grup: "Condomínio"
    }
  },
  {
    path: '/unidades',
    name: 'Unidades',
    component: Unidades,
    meta: {
      icon: "fa-solid fa-building-user",
      grup: "Condomínio"
    }
  },
  {
    path: '/blocos',
    name: 'Blocos',
    component: Blocos,
    meta: {
      icon: "fa-solid fa-building-user",
      grup: "Condomínio"
    }
  },
  {
    path: '/auth',
    name: 'Auth',
    component: Auth,
  },
  {
    path: '/license',
    name: 'Licença',
    component: License,
  },
  {
    path: '/perfiladm',
    name: 'Perfil de Administrador',
    component: PerfilAdministrador,
    meta: {
      icon: "fa-solid fa-user-gear",
      grup: "Cadastros"

    }
  },
  {
    path: '/empreendimento',
    name: 'Empreendimento',
    component: Empreendimento,
    meta: {
      icon: "fa fa-building",
      grup: "Condomínio"

    }
  },
  {
    path: '/cameraip',
    name: 'Câmeras Ip',
    component: CameraIp,
    meta: {
      icon: "fa-solid fa-video",
      grup: "Técnico"

    }
  },
  {
    path: '/',
    // beforeEnter: (to, from, next) => {
    //   // if (authStore) {
    //   //   next();
    //   // } else {
    //   //   if (from.path !== "/Home") {
    //   //     next("/aereo");
    //   //   } else {
    //   //     next();
    //   //   }
    //   // }
    // },
    name: 'Dashboard',
    component: dashboard,
  },
  // {
  //   path: '/Home',
  //   name: 'DashboardClient',
  //   component: clientHome,
  // },
  {
    path: '/tipo-visitante',
    name: 'Perfil de Visitantes',
    component: TipoVisitante,
    meta: {
      icon: "fa-solid fa-user-gear",
      grup: "Cadastros"
    }
  },

  {
    path: '/consulta-usuarios',
    name: 'Consulta de Usuários',
    component: ConsultaUsuarios,
    meta: {
      icon: "	fas fa-address-book",
      grup: "Relatórios"
    }
  },
  {
    path: '/relatorio-usuario/:id_user',
    name: 'Relatório usuário',
    component: RelatorioUsuario,
  },
  {
    path: '/relatorio-acesso',
    name: 'Relatório Acesso',
    component: RelatorioAcesso,
    meta: {
      grup: "Relatórios"
    }
  },
  {
    path: '/relatorio-acesso-visitante',
    name: 'Relatório Acesso Visitante',
    component: RelatorioAcessoVisitante,
    meta: {
      grup: "Relatórios"
    }
  },
  {
    path: '/relatorio-midia',
    name: 'Relatório Mídia',
    component: RelatorioMidia,
    meta: {
      grup: "Relatórios"
    }
  },
  {
    path: '/relatorio-tipo-usuario',
    name: 'Relatório de usuário',
    component: RelatorioTipoUsuario,
    meta: {
      grup: "Relatórios"
    }

  },

  {
    path: '/tipo-usuario',
    name: 'Tipo de Usuários',
    component: TipoUsuario,
    meta: {
      icon: "fa-solid fa-user-gear",
      grup: "Cadastros"

    }
  },
  // {
  //   path: '/gestao-menu',
  //   name: 'Gestão de Menu',
  //   component: GestaoMenu,
  //   meta: {
  //     icon: "fa-solid fa-screwdriver-wrench"
  //   }
  // },
  {
    path: '/dados-convite',
    name: 'Convite',
    component: Convite,
    meta: {
      icon: "fa-solid fa-envelope",
      grup: "Gerenciamento"
    }
  },
  {
    path: '/convite/:token',
    name: 'Convite Gerado',
    component: ConvidadoGerado,
  },
  {
    path: '/convite/user/:id',
    name: 'Convite Gerado',
    component: ConvidadoGerado,
  },
];
import { auth } from '@/store/auth'

import { getApp } from "@/firebaseConfig";
import { Get } from '@/core/services/ConfigService';
import { Only } from '@/core/services/EmpreendimentoService';
import { Me } from '@/core/services/UsuarioService';
import { Get as Menu } from "@/core/services/MenusPlanoService";
import { preloaderStore } from '@/store/preloaderStore';
import TipoVisitanteVue from '@/views/pages/tipoVisitante/TipoVisitante.vue';
const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active"
});
// import { getApp } from '@/firebaseConfig';
router.beforeEach(async (to, from, next) => {
  const authStore = auth();
  const preloader = preloaderStore();

  if (to.name == 'Convite Gerado') {
    next(true);
    return;
  }
  if (!to.fullPath.includes("bloqueado")) {
    preloader.setPreloader(true);
    // if (!authStore.empreendimento?.id) {
    //   authStore.ActionSetEmpreendimento(await Only());
    // }

    try {
      if (!authStore.empreendimento?.id) {
        authStore.ActionSetEmpreendimento(await Only());
      }
    } catch (error) {
      preloader.setPreloader(false);
      next(false);
    }

    let hasFirebase = false;
    if (!authStore.firebase) {
      var firebase = (await Get()).firebase;
      hasFirebase = await getApp(firebase);
      authStore.ActionSetFirebase(firebase);

    } else {
      hasFirebase = await getApp(authStore.firebase);
    }


    if (!to.fullPath.includes('license')) {
      if (hasFirebase) {

        try {
          const token = await authStore.ActionCheckToken();

          if (!token && !to.fullPath.includes("auth")) {
            preloader.setPreloader(false);
            next("/auth")
          } else {
            try {
              if (token && to.fullPath.includes("auth")) next("/");
              if (!authStore.getUserLogged?.id) {
                authStore.ActionSetUserLogged(await Me());
              }
              if (!authStore.getMenus || !authStore.getMenus.length) {
                authStore.ActionSetMenus(await Menu());
              }
              preloader.setPreloader(false);
              next(true);
            } catch (error) {
              preloader.setPreloader(false);
              next(false);
            }



          }
        } catch (error) {

          if (to.fullPath.includes("auth")) next(true);
          preloader.setPreloader(false);
          next("/auth")
        }



      } else {
        preloader.setPreloader(false);
        next("/license")
      }


    } else {
      if (hasFirebase) {

        try {

          preloader.setPreloader(true);
          const token = await authStore.ActionCheckToken();

          preloader.setPreloader(false);
          if (!token) {
            next("/auth")
          } else {
            next('/');
          }
        } catch (error) {
          preloader.setPreloader(false);
          if (to.fullPath.includes("auth")) next(true);
          next("/auth")
        }

      } else {
        preloader.setPreloader(false);
        next(true);
      }
      preloader.setPreloader(false);
      next(true);
    }
  } else {
    preloader.setPreloader(false);
    next(true);
  }

  // next(true);
})
export default { router, routes };
