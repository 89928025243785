
import * as faceAPI from 'face-api.js';
import { computed, onMounted, reactive, ref, watch } from 'vue';
export default {
  name: 'Video',
  setup(props, { emit }) {
    const initParams = reactive({
      modelUri: `${window.location.origin}/models`,
      option: new faceAPI.SsdMobilenetv1Options({ minConfidence: 0.5 }),
    });

    const constraints = computed(() => {
      return {
        video: {
          width: {
            min: 320,
            ideal: 353,
            max: 1920,
          },
          height: {
            min: 240,
            ideal: 353,
            max: 1080,
          },
          frameRate: {
            min: 15,
            ideal: 15,
            max: 60,
          },
          facingMode: useFrontCamera.value ? 'user' : 'environment',
        },
      };
    });

    // watch(
    //   () => tirar.value,
    //   () => {
    //     debugger;
    //     if (tirar.value) {
    //       videoEl.value.pause();
    //       extractFaceFromBox(videoEl.value);
    //     }
    //   }
    // );
    const videoEl = ref();
    const imageEl = ref();
    const canvasEl = ref();
    const tirar = ref(false);
    const videoStream = ref();
    const useFrontCamera = ref<boolean>(true);

    const board = reactive({
      realTimeCounts: 0,
      male: 0,
      female: 0,
      fps: 0,
    });
    let forwardTimes: any[] = [];
    /**
     * caculate fps for detection
     * @function
     * @param number
     */
    const updateTimeStats = (timeInMs) => {
      forwardTimes = [timeInMs].concat(forwardTimes).slice(0, 30);
      const avgTimeInMs = forwardTimes.reduce((total, t) => total + t) / forwardTimes.length;
      board.fps = faceAPI.utils.round(1000 / avgTimeInMs);
    };

    async function extractFaceFromBox(imageRef: HTMLVideoElement, box: any = undefined) {
      if (box) {
        const regionsToExtract = [new faceAPI.Rect(box.x - 33, box.y - 60, 480 / 2, 640 / 2)];
        let faceImages = await faceAPI.extractFaces(imageRef, regionsToExtract);
        if (faceImages.length === 0) {
          console.log('No face found');
        } else {
          console.log(faceImages[0].toDataURL('image/png'));
          emit('changeDimensions', faceImages[0].toDataURL('image/png'));
        }
      } else {
        const canvasEl = document.getElementById('canvas') as HTMLCanvasElement; // Tipificando como HTMLCanvasElement
        const imageEl = document.getElementById('video') as HTMLVideoElement; // Tipificando como HTMLVideoElement

        if (!canvasEl || !imageEl) {
          console.error('Elementos de canvas ou vídeo não encontrados');
          return;
        }

        const context = canvasEl.getContext('2d');
        if (context) {
          // Desenha o quadro atual do vídeo no canvas
          context.drawImage(imageEl, 0, 0, canvasEl.width, canvasEl.height);

          // Converte o conteúdo do canvas em uma URL de dados de imagem
          const dataUrl = canvasEl.toDataURL('image/png');
          emit('changeDimensions', dataUrl);
        } else {
          console.error('Não foi possível obter o contexto 2D do canvas');
        }
      }
    }

    const runModel = async () => {
      const beforeDetect = Date.now();
      const result = await faceAPI
        .detectSingleFace(videoEl.value as faceAPI.TNetInput, initParams.option as faceAPI.FaceDetectionOptions)
        .withAgeAndGender();
      updateTimeStats(Date.now() - beforeDetect);
      if (result) {
        const dims = faceAPI.matchDimensions(canvasEl.value, videoEl.value, true);
        const resizeResults = faceAPI.resizeResults(result, dims);
        // board.realTimeCounts =1
        // board.male =  resizeResults.filter(data => data.gender === 'male').length
        // board.female = resizeResults.filter(data => data.gender === 'female').length

        faceAPI.draw.drawDetections(canvasEl.value, resizeResults);
        if (resizeResults && tirar.value) {
          extractFaceFromBox(videoEl.value, resizeResults.detection.box);
          // saveCanvas(canvasEl.value);
          videoEl.value.pause();
          // stopVideoStream();
          return;
        } else if (tirar.value) {
       
          extractFaceFromBox(videoEl.value);
          videoEl.value.pause();
          return;
        }
      } else if (tirar.value) {
        extractFaceFromBox(videoEl.value);
        videoEl.value?.pause();

        return;
      }
      setTimeout(() => runModel());
    };
    async function changeCamera() {
      useFrontCamera.value = !useFrontCamera.value;
      initModel();
    }

    const returnGo = () => {
      stopVideoStream();
      emit('handleReturn', true);
    };
    /**
     * @function
     * @description load the trained model
     */
    const initModel = async () => {
      // await faceAPI.nets.ssdMobilenetv1.loadFromUri(initParams.modelUri)
      // await faceAPI.nets.ageGenderNet.loadFromUri(initParams.modelUri)
      Promise.all([
        faceAPI.nets.ssdMobilenetv1.loadFromUri(initParams.modelUri),
        // faceAPI.nets.faceRecognitionNet.loadFromUri(initParams.modelUri),
        // faceAPI.nets.faceLandmark68Net.loadFromUri(initParams.modelUri),
        faceAPI.nets.ageGenderNet.loadFromUri(initParams.modelUri),
      ])
        .then((val) => {
          // console here gives an array of undefined
          startStream();
        })
        .catch((err) => {});
    };
    /**
     * startup webcam
     * @function
     */
    const startStream = async () => {
      try {
        stopVideoStream();
        videoStream.value = await navigator.mediaDevices.getUserMedia(constraints.value);
        videoEl.value.srcObject = videoStream.value;
        imageEl.value.srcObject = videoStream.value;
        emit('start', true);
      } catch (error: any) {
        console.error(error.message);
      }
    };

    const stopVideoStream = () => {
      if (videoStream.value) {
        try {
          videoStream.value.getTracks().forEach((track) => {
            track.stop();
          });
        } catch (error) {
          console.error(error);
        }
      }
    };

    onMounted(() => {
      initModel();
    });
    return {
      videoEl,
      imageEl,
      canvasEl,
      runModel,
      tirar,
      board,
      changeCamera,
      returnGo,
    };
  },
};
